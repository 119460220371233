import { gql } from '@apollo/client';

const GET_GROUPS_QUERY = gql`
  query GetGroups($cookie: String!) {
    getGroups(cookie: $cookie) {
      Id
      Name
      Active
      Links {
        rel
        href
      }
      
    }
  }
`;

export default GET_GROUPS_QUERY;
