import { useFormik } from 'formik';
import * as Yup from 'yup';

const useCustomForm = ({ fileCabinetList, handleNextStep, isEditMode = false, model = null }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    cabinetList: Yup.array()
      .test('cabinetList', 'Choose a cabinet', (cabs) => cabs.filter((cab) => cab.isSelected).length > 0)
      .required('Choose a cabinet'),
  });

  const initialValues = {
    title: isEditMode ? model.title : '',
    cabinetList: isEditMode
      ? fileCabinetList.map((cab) => (cab.Id === model.filecabinetId ? { ...cab, isSelected: true } : { ...cab, isSelected: false }))
      : fileCabinetList.map((cab) => ({ ...cab, isSelected: false })),
  };

  const onSubmit = ({ title }, helpers) => {
    try {
      handleNextStep(title);
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return formik;
};

export default useCustomForm;
