import React, { useState } from 'react';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    CircularProgress
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles/AddDialog.module.css';
export const AddDialog = ({ onClose, tree, onSubmit, searchFields, loading }) => {
    const [field, setField] = useState('');
    const [parent, setParent] = useState(0);
    const [displayName, setDisplayName] = useState('');

    const handleChangeNodeName = (e, node) => {
        setField(e.target.value);
        setDisplayName(node?.props?.children || '');

    };

    const handleChangeParent = (e) => {
        setParent(Number(e.target.value));
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle sx={{ padding: 2 }}>Ajouter un nouveau dossier</DialogTitle>
            <DialogContent className={styles.content}>
                <div>
                    <FormControl className={styles.select}>
                        <InputLabel>Nom du dossier</InputLabel>
                        {loading ? <Stack spacing={2}>
                            <CircularProgress />
                        </Stack> :
                            <Select label="Parent" onChange={handleChangeNodeName} value={field}>

                                {searchFields
                                    .map((node) => (
                                        <MenuItem key={uuidv4()} value={node.DBFieldName}>
                                            {node.DisplayName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        }
                    </FormControl>
                </div>
                <div>
                    <FormControl className={styles.select}>
                        <InputLabel>Parent</InputLabel>
                        <Select label="Parent" onChange={handleChangeParent} value={parent}>
                            <MenuItem value={0}>(Dossier racine)</MenuItem>
                            {tree
                                .map((node) => (
                                    <MenuItem key={uuidv4()} value={node.id}>
                                        {node.text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button
                    onClick={() => onSubmit({
                        text: displayName,
                        DBFieldName: field,
                        parent,
                        droppable: true,
                    })}
                >
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
};
