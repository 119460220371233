import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import Login from './Components/Auth/Login';
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import ModelExtraction from './Pages/ModelExtraction';
import MyExtractions from './Pages/MyExtractions';
import ExtractionList from './Pages/ExtractionList';
import NewExtraction from './Pages/NewExtraction';
import MyModels from './Pages/MyModels';
import { createTheme } from './theme';

const App = () => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route element={<Login />} exact path="/" />
          <Route element={<Dashboard />} exact path="/dashboard" />
          <Route element={<Users />} exact path="/users" />
          <Route element={<ModelExtraction />} exact path="/extraction-model" />
          <Route element={<MyExtractions />} exact path="/my-extractions" />
          <Route element={<ExtractionList />} exact path="/extraction-list" />
          <Route element={<NewExtraction />} exact path="/new-extraction" />
          <Route element={<MyModels />} exact path="/models" />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
