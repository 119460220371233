import { gql } from '@apollo/client';

export const GET_ACCESSIBLE_EXTRACTIONS = gql`
  query GetAccessibleExtractions($user_id: String!) {
    getAccessibleExtractions(user_id: $user_id) {
      id
      title
      create_at
      status
      zip_link
      log_link
    }
  }
`;
