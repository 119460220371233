/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import AddIcon from '@mui/icons-material/Add';
import { Tree, MultiBackend, getDescendants, getBackendOptions } from '@minoru/react-dnd-treeview';
import { CustomNode } from '../Shared/CustomNode';
import CustomDragPreview from '../Shared/CustomDragPreview';
import { AddDialog } from '../Shared/AddDialog';
import { Box, Stack, Button } from '@mui/material';
import { useAdminSearchCriteria } from '../../Hooks/use-Docuware';
import Cookies from 'js-cookie';

const getLastId = (treeData) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }
    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};

const DefineModelTree = ({ setTreeData, treeData, selectedCabinet }) => {
  const treeRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openNodes, setOpenNodes] = useState(() => treeData.map(node => node.id));
  const { loading, error, data } = useAdminSearchCriteria(Cookies.get('access_token'), selectedCabinet);

  useEffect(() => {
    // Automatically open nodes with children
    const nodesWithChildren = treeData
      .filter(node => node.droppable && getDescendants(treeData, node.id).length > 0)
      .map(node => node.id);

    // Add the root node ID to the openNodes state
    const rootNode = treeData.find(node => node.parent === undefined);
    const rootNodeId = rootNode ? rootNode.id : null;
    setOpenNodes([...nodesWithChildren, rootNodeId]);
  }, [treeData]);



  const handleDrop = (newTree, { dragSource, dropTarget }) => {
    setTreeData(newTree);

    if (dropTarget) {
      setOpenNodes(prevOpen => Array.from(new Set([...prevOpen, dropTarget.id])));
      if (treeRef.current) {
        treeRef.current.open(dropTarget.id);
      }
    }
  };

  const handleDelete = (id) => {
    const deleteIds = [id, ...getDescendants(treeData, id).map((node) => node.id)];
    const newTree = treeData.filter((node) => !deleteIds.includes(node.id));
    setTreeData(newTree);
  };

  const handleCopy = (id) => {
    const lastId = getLastId(treeData);
    const targetNode = treeData.find((n) => n.id === id);
    const descendants = getDescendants(treeData, id);
    const partialTree = descendants.map((node) => ({
      ...node,
      id: node.id + lastId,
      parent: node.parent + lastId,
    }));

    setTreeData([
      ...treeData,
      {
        ...targetNode,
        id: targetNode.id + lastId,
      },
      ...partialTree,
    ]);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = (newNode) => {
    const lastId = getLastId(treeData) + 1;
    setTreeData([
      ...treeData,
      {
        ...newNode,
        id: lastId,
      },
    ]);
    if (newNode.parent !== undefined) {
      setOpenNodes(prevOpen => Array.from(new Set([...prevOpen, newNode.parent])));
      if (treeRef.current) {
        treeRef.current.open(newNode.parent);
      }
    }
    setOpen(false);
  };

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Box>
        <Stack>
          <Tree
            ref={treeRef}
            dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
            onDrop={handleDrop}
            render={(node, options) => (
              <CustomNode
                node={node}
                {...options}
                onCopy={handleCopy}
                onDelete={handleDelete}
              />
            )}
            rootId={0}
            tree={treeData}
            open={openNodes}
            initialOpen={openNodes}
            onToggle={(id) => setOpenNodes(prevOpen =>
              prevOpen.includes(id) ? prevOpen.filter(nodeId => nodeId !== id) : [...prevOpen, id]
            )}
          />
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'center' }}>
          <Button onClick={handleOpenDialog} startIcon={<AddIcon />} variant="contained">
            Ajouter un dossier
          </Button>
          {open ? (
            <AddDialog
              loading={loading}
              onClose={handleCloseDialog}
              onSubmit={handleSubmit}
              searchFields={data?.getAdminSearchCriteria}
              tree={treeData}
            />
          ) : null}
        </Stack>
      </Box>
    </DndProvider>
  );
};

export default DefineModelTree;
