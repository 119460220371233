import { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import LOGIN_MUTATION from '../Graphql/Mutation/auth-mutation';
import Cookies from 'js-cookie';

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  ERROR_SIGN_IN: 'ERROR_SIGN_IN',
  LOADING: 'LOADING',
};

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  isLoading: false,
  user: null,
  error: null,
};

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext({ undefined });

export const AuthProvider = ({ children }) => {

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case HANDLERS.LOADING:
        return { ...state, isLoading: action.payload };

      case HANDLERS.INITIALIZE: {
        let user = JSON.parse(localStorage.getItem('user'));
        let updates = user ? { isAuthenticated: true, isLoading: false, user, isAdmin: user.isAdmin, } : { isLoading: false };
        return { ...state, ...updates };
      }
      case HANDLERS.SIGN_IN: {
        let user = action.payload;
        localStorage.setItem('user', JSON.stringify(user));
        return { ...state, isAuthenticated: true, user, isAdmin: user.isAdmin, isLoading: false };
      }
      case HANDLERS.SIGN_OUT:
        localStorage.removeItem('user');

        return { ...state, isAuthenticated: false, user: null, isAdmin: null };
      case HANDLERS.ERROR_SIGN_IN:
        return { ...state, error: action.payload, isAuthenticated: false, isLoading: false };
      default:
        throw new Error(`Unsupported action type ${action.type}`);
    }
  }, initialState);
  const initialize = async () => {
    if (state.initialized) return;

    dispatch({ type: HANDLERS.LOADING, payload: true });
    let user = JSON.parse(localStorage.getItem('user'));

    dispatch({ type: HANDLERS.INITIALIZE, payload: user ? user : undefined });
    dispatch({ type: HANDLERS.LOADING, payload: false });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [login] = useMutation(LOGIN_MUTATION, {
    onError: (error) => dispatch({ type: HANDLERS.ERROR_SIGN_IN, payload: error }),
    errorPolicy: 'all',
    onCompleted: (data) => {
      try {
        const userData = { ...data.login, timestamp: new Date().getTime() };

        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('isAuthenticated', 'true'); // Store isAuthenticated state in local storage
        dispatch({ type: HANDLERS.SIGN_IN, payload: userData });
      } catch (err) {
        console.error('setup auth session storage', err);
      }
    },
  });

  const signIn = async (username, password) => login({ variables: { username, password } });
  const signOut = () => {
    Cookies.remove('qid');
    Cookies.remove('access_token');
    localStorage.removeItem('user');
    localStorage.setItem('isAuthenticated', 'false'); // Update isAuthenticated state in local storage
    dispatch({ type: HANDLERS.SIGN_OUT });
  };
  const checkisSessionExpired = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && new Date().getTime() - user.timestamp > 12 * 60 * 60 * 1000) {
      // If the session has expired, sign out the user

      signOut();
      return true;
    }
    return false;
  };

  return (
    <AuthContext.Provider value={{ ...state, signIn, signOut, checkisSessionExpired }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = { children: PropTypes.node };

export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);
