/* eslint-disable react/jsx-max-depth */
import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArticleIcon from '@mui/icons-material/Article';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton from MUI
import { useQuery } from '@apollo/client';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import useAuth from '../Hooks';
import { GET_ACCESSIBLE_EXTRACTIONS } from '../Graphql/Query/get-accessibleExtractions-query';
import SearchComponent from '../Components/Shared/Search';
import React, { useState } from 'react';
const Page = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');

  const { loading, error, data } = useQuery(GET_ACCESSIBLE_EXTRACTIONS, {
    variables: { user_id: user?.id },
    fetchPolicy: 'cache-and-network',
  });

  const extractions = data?.getAccessibleExtractions || [];
  const getStatusIcon = (status) => {
    if (status === 'success') {
      return <CheckCircleOutlineIcon style={{ color: 'green' }} />;
    } else if (status === 'pending' || status === 'new') {
      return <PauseCircleOutlineIcon style={{ color: 'gray' }} />;
    } else if (status === 'failure') {
      return <HighlightOffIcon style={{ color: 'red' }} />;
    } else if (status === 'in progress') {
      return <PauseCircleOutlineIcon style={{ color: 'orange' }} />;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };
  function sortExtractions(extractions, order) {
    return [...extractions].sort((extraction1, extraction2) => {
      const date1 = new Date(extraction1.create_at);
      const date2 = new Date(extraction2.create_at);
      if (order === 'asc') {
        return date1.getTime() - date2.getTime();
      } else {
        return date2.getTime() - date1.getTime();
      }
    });
  }
  if (loading) {
    return (
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Skeleton height={40} variant="text" width="20%" />
              </div>
              <div>
                <Skeleton height={50} variant="rectangular" width="100%" />
              </div>
              <div>
                <Skeleton height={400} variant="rectangular" width="100%" />
              </div>
            </Stack>
          </Container>
        </Box>
      </DashboardLayout>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Liste des Extractions</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid lg={12} md={12} xs={12}>
                  <div>
                    <SearchComponent handleSearchChange={handleSearchChange} title="recherche par titre d’extraction..." />
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Titre</TableCell>
                          <TableCell>
                            <TableSortLabel active direction={order} onClick={handleSort}>
                              Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Zip</TableCell>
                          <TableCell>Journal</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortExtractions(extractions, order)
                          .filter((extraction) => {
                            if (searchTerm === '') {
                              return true;
                            }
                            return extraction.title.includes(searchTerm);
                          })
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                          .map((extraction) => (
                            <TableRow key={extraction.id}>
                              <TableCell>{extraction.title}</TableCell>
                              <TableCell>{extraction.create_at}</TableCell>
                              <TableCell>{getStatusIcon(extraction.status)}</TableCell>
                              <TableCell>
                                {extraction.status === 'success' && extraction.zip_link ? (
                                  <Link aria-label="Zip file link" href={extraction.zip_link} title="Zip file link">
                                    <FolderZipIcon />
                                  </Link>
                                ) : (
                                  ' - '
                                )}
                              </TableCell>
                              <TableCell>
                                {(extraction.status === 'success' || extraction.status === 'failure') &&
                                  extraction.log_link ? (
                                  <a download href={extraction.log_link}>
                                    <ArticleIcon />
                                  </a>
                                ) : (
                                  ' - '
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={extractions.length}
                      labelRowsPerPage="Ligne par page:"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Page;
