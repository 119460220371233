/* eslint-disable react/jsx-no-leaked-render */

import { Box, Container, Stack, Typography } from '@mui/material';
import CreateModelTree from './CreateModelTree';
import { useDocuWare } from '../../Hooks';
import { useCabQuery } from '../../Hooks/use-Docuware';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ModelTree = () => {
  const docuwareInfo = useDocuWare();
  const cabInfo = useCabQuery(Cookies.get('access_token'));
  const { state } = useLocation();
  const isEditMode = !!state;

  const model = state;
  const [cabinets, setCabinets] = useState([]);
  useEffect(() => {
    if (!cabInfo.loading && cabInfo.data && cabInfo.data.getCabinets) {
      setCabinets(cabInfo.data.getCabinets);
    }
  }, [cabInfo]);

  const getDocuwareInfo = async () => {
    docuwareInfo.getUsersList();
    docuwareInfo.setCabinets(cabInfo);
  };
  useEffect(() => {
    getDocuwareInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      {model && (
        <Typography
          variant="h5"
          sx={{
            position: 'relative',
            top: '-20px',
            right: '-25px', // adjust this value to move it more to the right
            color: '#1a1a2e', // dark blue color
            mt: 2,
          }}
        >
          Le modèle en cours de modification: {model.title}
        </Typography>
      )}
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <CreateModelTree
            fileCabinetList={cabinets}
            isEditMode={isEditMode}
            loading={cabInfo?.loading}
            model={model}
          />
        </Stack>
      </Container>
    </Box>
  );
};
export default ModelTree;
