/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import SET_NEW_MODEL_MUTATION from '../Graphql/Mutation/set-new-model-mutation';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_MODEL_MUTATION from '../Graphql/Mutation/update-model-mutation';
import UPDATE_PERMISSION_MODEL from '../Graphql/Mutation/update-permissionmodel-mutation.js';
const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SET_CABINET: 'SET_CABINET',
  SET_JSON_ARRAY_FILTER: 'SET_JSON_ARRAY_FILTER',
  SET_JSON_ARRAY_TREE: 'SET_JSON_ARRAY_TREE',
  SET_TITLE: 'SET_TITLE',
  SET_USER_PRIVILEGE: 'SET_USER_PRIVILEGE',
  SET_ERROR: 'SET_ERROR',
  UPDATE_MODEL: 'UPDATE_MODEL',
  SET_COOKIE: 'SET_COOKIE',
  SET_GROUPS: 'SET_GROUPS',
  SET_STEP: 'SET_STEP',
  ADD_LOOP: 'ADD_LOOP',

};

const initialState = {
  title: {},
  cabinet: {},
  jsonArrayFilter: [],
  jsonArrayTree: [],
  finalJSON: [],
  users: [],
  error: null,
  cookie: null,
  groups: [],
  step: 1,
  loops: [],

};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, { cabinet, error }) => {
    return {
      ...state,
      ...(cabinet || { error }),
    };
  },
  [HANDLERS.SET_CABINET]: (state, { payload }) => {
    return {
      ...state,
      cabinet: { ...payload },
    };
  },
  [HANDLERS.SET_ERROR]: (state, { payload }) => {
    return {
      ...state,
      error: payload,
    };
  },
  [HANDLERS.SET_JSON_ARRAY_FILTER]: (state, { payload }) => {
    return {
      ...state,
      jsonArrayFilter: payload,//[...state.jsonArrayFilter, ...payload],
    };
  },
  [HANDLERS.SET_JSON_ARRAY_TREE]: (state, { payload }) => {
    return {
      ...state,
      jsonArrayTree: [...state.jsonArrayTree, payload],
    };
  },
  [HANDLERS.SET_STEP]: (state, { payload }) => {
    return {
      ...state,
      step: payload,
    };
  },
  [HANDLERS.ADD_LOOP]: (state, { payload }) => {
    return {
      ...state,
      loops: [...state.loops, payload],
    };
  },
  [HANDLERS.SET_MODEL_TREE]: (state, { payload }) => {
    return {
      ...state,
      finalJSON: [...state.finalJSON, payload],
    };
  },
  [HANDLERS.SET_TITLE]: (state, { payload }) => {
    return {
      ...state,
      title: payload,
    };
  },
  [HANDLERS.SET_USER_PRIVILEGE]: (state, { payload }) => {
    return {
      ...state,
      users: payload,
    };
  },
  [HANDLERS.UPDATE_MODEL]: (state, { payload }) => {
    return {
      ...state,
      jsonArrayFilter: payload.newFilterArray,
      loops: payload.loops,
    };
  },
  [HANDLERS.SET_COOKIE]: (state, { payload }) => {
    return {
      ...state,
      cookie: payload,
    };
  },
  [HANDLERS.SET_GROUPS]: (state, { payload }) => {
    return {
      ...state,
      groups: payload,
    };
  },
  [HANDLERS.SET_FINAL_JSON]: (state, { payload }) => {
    return {
      ...state,
      finalJSON: payload,
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

// The role of this context is to propagate authentication state through the App tree.

export const ModelTreeContext = createContext(initialState);

export const ModelTreeProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    dispatch({
      type: HANDLERS.INITIALIZE,
    });
  };

  useEffect(() => {
    initialize();
  }, []);
  const setContextCabinet = (cabinet) => {
    const cab = cabinet[0];
    try {
      dispatch({
        type: HANDLERS.SET_CABINET,
        payload: cab,
      });
    } catch (error) {
      dispatch({
        type: HANDLERS.SET_ERROR,
        payload: error,
      });
    }
  };
  const setModelTitle = (title) => {
    try {
      dispatch({
        type: HANDLERS.SET_TITLE,
        payload: title,
      });
    } catch (error) {
      dispatch({
        type: HANDLERS.SET_ERROR,
        payload: error,
      });
    }
  };
  const setJsonArrayFilter = (filterArray) => {
    dispatch({
      type: HANDLERS.SET_JSON_ARRAY_FILTER,
      payload: filterArray,
    });
  };
  const setJsonArrayTree = (payload) => {
    const arrayTree = {
      id: uuidv4(),
      path: [...payload],
    };
    dispatch({
      type: HANDLERS.SET_JSON_ARRAY_TREE,
      payload: arrayTree,
    });
  };
  const setStep = (step) => {
    dispatch({
      type: HANDLERS.SET_STEP,
      payload: step,
    });
  };

  const addLoop = (loop) => {
    dispatch({
      type: HANDLERS.ADD_LOOP,
      payload: loop,
    });
  };

  const setCookie = (payload) => {
    dispatch({
      type: HANDLERS.SET_COOKIE,
      payload: payload,
    });
  };

  const setGroups = (payload) => {
    dispatch({
      type: HANDLERS.SET_GROUPS,
      payload: payload,
    });
  };

  const setUsersPrivilege = async (payload) => {
    let users = [];
    let groups = [];

    // Iterate over the payload
    for (let item of payload) {
      let isGroup = false;
      // Check if the item is a group
      if (state.groups?.find(group => group.Id === item)) {
        isGroup = true;

        groups.push(item);
      }
      // If it's not a group, just add the item to the users array (meaning it's a single user)

      if (!isGroup) {
        users.push(item);
      }
    }

    dispatch({
      type: HANDLERS.SET_USER_PRIVILEGE,
      payload: users,
    });
    return { users, groups };
  };
  const [setNewModel, { data, error }] = useMutation(SET_NEW_MODEL_MUTATION, {
    errorPolicy: 'all',
  });
  const setFinalJSON = async (users_groups) => {
    const { users, groups, loops } = users_groups;
    const payload = {
      id_model: Math.round(Math.random() * 100000),
      jsonarraytree: state.jsonArrayTree,
      jsonarrayfilter: state.jsonArrayFilter,
      title: state.title,
      cabinet: state.cabinet.Id,
      users: [...state.users, ...users],
      groups: groups,
      loops: loops,

    };

    dispatch({
      type: HANDLERS.SET_FINAL_JSON,
      payload: payload,
    });
    const res = await setNewModel({ variables: { newModel: payload } });
    console.log('🚀 ~ setFinalJSON ~ res:', res);

    if (error) {
      console.log('🚀 ~ setFinalJSON ~ error:', error);

      throw new Error('Please check the error: setFinalJson L:176', error);
    }
    if (data?.setNewModel) {
      console.log('🚀 ~ setFinalJSON ~ data?.setNewModel:', data?.setNewModel);
      return {
        success: true,
      };
    }
  };
  const [handleUpdate, { error: updatemodelerror }] = useMutation(UPDATE_MODEL_MUTATION);
  const updateModel = async (model_id, loops) => {
    const payload = {
      model_id: model_id,
      newFilterArray: state.jsonArrayFilter,
      loops: loops,
    };
    dispatch({
      type: HANDLERS.UPDATE_MODEL,
      payload: payload,
    });
    try {
      await handleUpdate({ variables: payload });
      return {
        success: true,
      };
    } catch (updatemodelerror) {
      console.log('Error updating model:', updatemodelerror);
      throw new Error('Error updating model:', updatemodelerror);
    }
  };

  const [updatePermissionModel] = useMutation(UPDATE_PERMISSION_MODEL);
  const updatePermission = async (modelId, users) => {
    try {
      const result = await updatePermissionModel({ variables: { modelId, users } });
      console.log(result);
      // handle successful update
    } catch (error) {
      console.error(error);
      // handle error
    }
  };
  return (
    <ModelTreeContext.Provider
      value={{
        ...state,
        setModelTitle,
        setContextCabinet,
        setJsonArrayFilter,
        setJsonArrayTree,
        setFinalJSON,
        setUsersPrivilege,
        updatePermission,
        updateModel,
        setCookie,
        setGroups,
        setStep,
        addLoop,

      }}
    >
      {children}
    </ModelTreeContext.Provider>
  );
};

ModelTreeProvider.propTypes = {
  children: PropTypes.node,
};

export const ModelTreeConsumer = ModelTreeContext.Consumer;

export const useModelTreeContext = () => useContext(ModelTreeContext);
