/* eslint-disable react/jsx-max-depth */
import {
  CardContent,
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  FormGroup,
  TextField,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';

import { SearchComponent } from '../Shared';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import TablePagination from '@mui/material/TablePagination';




const PickCabinetStep = ({ cabinetList, activeStep, formik, loading, setFilecabinetid }) => {
  const [searchCabinetField, setSearchCabinetField] = useState('');
  const [filteredCabinetList, setFilteredCabinetList] = useState(cabinetList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // Pre-populate title if it exists
    if (formik.values.title) {
      formik.setFieldValue('title', formik.values.title);
    }

    // Pre-select cabinet if one is already selected
    const selectedCabinet = cabinetList.find(cab => cab.isSelected);
    if (selectedCabinet) {
      formik.setFieldValue('cabinetList', cabinetList.map(cab =>
        cab.Id === selectedCabinet.Id ? { ...cab, isSelected: true } : { ...cab, isSelected: false }
      ));
      setFilecabinetid(selectedCabinet.Id);
    }
  }, []);
  useEffect(() => {
    const handleSearchChange = () => {
      if (searchCabinetField !== '') {
        setFilteredCabinetList(
          cabinetList.filter((cab) => cab.Name.toLowerCase().includes(searchCabinetField.toLowerCase()))
        );
      } else {
        setFilteredCabinetList(cabinetList);
      }
    };
    handleSearchChange();
  }, [searchCabinetField, cabinetList]);

  const chooseCabinet = (id) => {
    const newCabinetList = cabinetList.map((el) =>
      el.Id === id ? { ...el, isSelected: !el.isSelected } : { ...el, isSelected: false }
    );
    const selected = newCabinetList.find((el) => el.isSelected);
    setFilecabinetid(selected?.Id);
    formik.setFieldValue('cabinetList', newCabinetList);
  };
  return (
    <CardContent>
      <Grid container spacing={12} wrap="wrap">
        <Grid md={12} sm={12} xs={12}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Grid item={true.toString()} md={4}>
                <Typography sx={{ m: 2 }} variant="h6">
                  Titre du modèle<sup>*</sup>:
                </Typography>
              </Grid>
              <Grid item={true.toString()} md={8}>
                <FormGroup>
                  <TextField
                    error={!!(formik.touched.title && formik.errors.title)}
                    id="title"
                    name="title"
                    onChange={formik.handleChange}
                    placeholder="entrez votre titre ici ..."
                    value={formik.values.title}
                    variant="outlined"
                  />
                  <Stack spacing={1}>
                    {formik.errors.title ? (
                      <Typography color="error" sx={{ mt: 3 }} variant="body2">
                        {formik.errors.title}
                      </Typography>
                    ) : null}
                  </Stack>
                </FormGroup>
              </Grid>
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
          <Stack spacing={2}>
            <Typography variant="h6">
              Sélectionnez votre armoire<sup>*</sup>:
            </Typography>
            <SearchComponent handleSearchChange={(e) => setSearchCabinetField(e.target.value)} title="rechercher..." />
          </Stack>
          <Divider />

          {loading ? (
            <Stack spacing={2}>
              <CircularProgress />
            </Stack>
          ) : (
            <><List>
              {filteredCabinetList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cab, index) => (
                <ListItem
                  key={cab.Id}
                  onClick={() => {
                    formik.setFieldTouched('cabinetList', true);
                    chooseCabinet(cab.Id);
                  }}
                >
                  <ListItemText primary={`${index + 1}. ${cab.Name}`} />
                  <Radio checked={cab.isSelected} />
                </ListItem>
              ))}
            </List><TablePagination
                component="div"
                count={filteredCabinetList.length}
                labelRowsPerPage="Ligne par page:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]} /></>
          )}
        </Grid>
      </Grid>
    </CardContent>
  );
};
PickCabinetStep.propTypes = {
  cabinetList: PropTypes.array.isRequired,
  formik: PropTypes.object,
};
export default PickCabinetStep;
