import { gql } from '@apollo/client';

const ADD_EXTRACTION = gql`
  mutation AddExtraction($extraction: JSON!) {
    addExtraction(extraction: $extraction) {
      id
    }
  }
`;

export default ADD_EXTRACTION;
