/* eslint-disable react/jsx-max-depth */
import { useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import UserPrivilegeTable from './UserPrivilegeTable';

const DefineUserPrivilege = ({ userSelection, users, groups }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Liste des utilisateurs:</Typography>
            </Stack>
          </Stack>
          <UserPrivilegeTable
            count={(users?.length || 0) + (groups?.length || 0)}
            items={users}
            groups={groups}
            onDeselectAll={userSelection.handleDeselectAll}
            onDeselectOne={userSelection.handleDeselectOne}
            onSelectAll={userSelection.handleSelectAll}
            onSelectOne={userSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            selected={userSelection.selected}
          />
        </Stack>
      </Container>
    </Box>
  );
};

DefineUserPrivilege.propTypes = {
  userSelection: PropTypes.object,
  users: PropTypes.array,
  groups: PropTypes.array,
};

export default DefineUserPrivilege;
