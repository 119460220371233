import { useCallback, useEffect, useState } from 'react';

export const useSelection = (items = [], groups = []) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (items.length === 0 && groups.length === 0) {
            setSelected([]);
        }
    }, [items, groups]);

    const handleSelectAll = useCallback(() => {
        setSelected([...items, ...groups]);
    }, [items, groups]);

    const handleSelectOne = useCallback((item) => {
        setSelected((prevState) => [...prevState, item]);
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelected([]);
    }, []);

    const handleDeselectOne = useCallback((item) => {
        setSelected((prevState) => {
            return prevState.filter((_item) => _item !== item);
        });
    }, []);

    return {
        handleDeselectAll,
        handleDeselectOne,
        handleSelectAll,
        handleSelectOne,
        selected,
        setSelected
    };
};
