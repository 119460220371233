/* eslint-disable react/jsx-max-depth */
/* eslint-disable quotes */
// eslint-disable-next-line react/jsx-max-depth
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import useAuth from '../../Hooks/index';
import { Layout as AuthLayout } from '../Layout/Layout';
import docuroomLogo from '../../assets/Logo-Docuroom.svg';
import gecinaLogo from '../../assets/Gecina_logo-CMJN.svg';
const FormInput = ({ formik, name, label, type }) => (
  <TextField
    error={Boolean(formik.touched[name] && formik.errors[name])}
    fullWidth
    helperText={formik.touched[name] ? formik.errors[name] : null}
    label={label}
    name={name}
    onBlur={formik.handleBlur}
    onChange={formik.handleChange}
    type={type}
    value={formik.values[name]}
  />
);
const Page = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  useEffect(() => {
    const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));
    const user = JSON.parse(localStorage.getItem('user'));
    if (isAuthenticated && user.isAdmin) {
      navigate('/models', { replace: true });
    }
    else if (isAuthenticated && (user.isAdmin === false)) {
      navigate('/my-extractions', { replace: true });
    }
  }, [navigate]);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      submit: null
    },
    onSubmit: async (values, helpers) => {
      try {
        await signIn(values.username, values.password);
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.isAdmin) {
          navigate('/models', { replace: true });
        } else {
          navigate('/my-extractions', { replace: true });
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: `Connexion échouée, veuillez réessayer.` });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <AuthLayout>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
            </Stack>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Stack spacing={3}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 3
                  }}
                >
                  <Box
                    component="img"
                    src={gecinaLogo}
                    alt="Gecina Logo"
                    sx={{
                      height: 60,  // Increased from 30 to 60
                      width: 'auto',
                      mt: 1,
                      mr: 3  // Increased margin for better spacing
                    }}
                  />
                  <Box
                    component="img"
                    src={docuroomLogo}
                    alt="Docuroom Logo"
                    sx={{
                      height: 40
                    }}
                  />
                </Box>
                <FormInput formik={formik} label="nom d'utilisateur" name="username" type="text" />
                <FormInput formik={formik} label="mot de passe" name="password" type="password" />
              </Stack>
              {formik.errors.submit ? (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              ) : null}
              <Button
                fullWidth
                size="large"
                sx={{
                  mt: 3,
                  backgroundColor: 'black',
                  color: 'white'
                }}
                type="submit"
                variant="contained"
              >
                Se Connecter
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Page;
