import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import QueueList from '@heroicons/react/24/solid/QueueListIcon';
import DocumentText from '@heroicons/react/24/solid/DocumentTextIcon';
import RectangleGroup from '@heroicons/react/24/solid/RectangleGroupIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import FolderPlusIcon from '@heroicons/react/24/solid/FolderPlusIcon';
import WalletIcon from '@heroicons/react/24/solid/WalletIcon';
import { SvgIcon } from '@mui/material';

export const items = [
  {
    title: 'Créer un modèle',
    path: '/extraction-model',
    icon: (
      <SvgIcon fontSize="small">
        <RectangleGroup />
      </SvgIcon>
    ),
    isUserTool: false,
  },
  {
    title: 'Liste des modèles',
    path: '/models',
    icon: (
      <SvgIcon fontSize="small">
        <WalletIcon />
      </SvgIcon>
    ),
    isUserTool: false,
  },
  {
    title: 'Créer une extraction',
    path: '/new-extraction',
    icon: (
      <SvgIcon fontSize="small">
        <FolderPlusIcon />
      </SvgIcon>
    ),
    isUserTool: true,

  },
  {
    title: 'Liste des extractions',
    path: '/my-extractions',
    icon: (
      <SvgIcon fontSize="small">
        <DocumentText />
      </SvgIcon>
    ),
    isUserTool: true,
  },
  {
    title: 'Utilisateurs',
    path: '/users',
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
    isUserTool: false,
  },





];
