import { useQuery } from '@apollo/client';
import GET_GROUPS_QUERY from '../Graphql/Query/get-groups-query';

export const useGroups = (token) => {
    const { loading, error, data } = useQuery(GET_GROUPS_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            cookie: token
        }
    });
    if (loading) {
        return { loading: true };
    }

    if (error) {
        return { error: true };
    }

    return { groups: data.getGroups };
};
