/* eslint-disable react/jsx-max-depth */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { useQuery, useMutation } from '@apollo/client';
import { GET_MODELS } from '../Graphql/Query/get-models-query';
import EditIcon from '@mui/icons-material/Edit';
import {
  TableCell,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  Box,
  Container,
  Stack,
} from '@mui/material';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';
import TablePagination from '@mui/material/TablePagination';
import DELETE_MODEL_MUTATION from '../Graphql/Mutation/delete-model';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { useState, useEffect } from 'react';

const MyModels = () => {
  const location = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const successMsg = location.state?.successMessage;

    if (successMsg) {
      setSuccessMessage(successMsg);
      setOpenSnackbar(true);
      window.history.replaceState({}, document.title);

    }
  }, [location]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const {
    loading: modelsLoading,
    error: modelsError,
    data: modelsData,
    refetch: refetchModels,
  } = useQuery(GET_MODELS, {
    fetchPolicy: 'no-cache',
  });
  const [deleteModel] = useMutation(DELETE_MODEL_MUTATION);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleDeleteAndRefetch = async (model_id) => {
    try {
      await deleteModel({ variables: { model_id } });
      refetchModels();
    } catch (error) {
      if (error.message.includes('409')) {
        setOpen(true); // open the popup if the error message includes '409' (meaning conflict error)
      }
    }
  };


  if (modelsLoading) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Skeleton height={40} variant="text" width="20%" />
            </div>
            <div>
              <Skeleton height={50} variant="rectangular" width="100%" />
            </div>
            <div>
              <Skeleton height={400} variant="rectangular" width="100%" />
            </div>
          </Stack>
        </Container>
      </Box>
    );
  }

  if (modelsError) {
    return <div>Error: {modelsError.message}</div>;
  }

  const handleCloseSnackbar = (event, reason) => {

    setOpenSnackbar(false);

  };
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Modèles Définis</Typography>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Titre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modelsData.getModels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((model) => (
                    <TableRow key={model.id_model}>
                      <TableCell>{model.id_model}</TableCell>
                      <TableCell>{model.title}</TableCell>

                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigate('/extraction-model', { state: model });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton color="secondary" onClick={() => handleDeleteAndRefetch(model.id_model)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={modelsData.getModels.length}
                labelRowsPerPage="Ligne par page:"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </TableContainer>
          </Stack>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        open={openSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%', fontSize: '1.5em' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Modèle Référencé dans les extractions, impossible de supprimer
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={() => setOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default MyModels;
