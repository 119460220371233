// getSearchCriteria.js
import { gql } from '@apollo/client';

export const GET_SEARCH_CRITERIA = gql`
  query GetSearchCriteria($cookie: String!, $loops: JSON!, $filecabinetId: String!, $extraction_id: Int!) {
    getSearchCriteria(cookie: $cookie, loops: $loops, filecabinetId: $filecabinetId, extraction_id: $extraction_id)
  }
`;

export const GET_CABINETS = gql`
  query GetCabinets($cookie: String!) {
    getCabinets(cookie: $cookie) {
      Color
      Name
      Id
      IsBasket
      Usable
      Default
      VersionManagement
      WindowsExplorerClientAccess
      AddIndexEntriesInUpperCase
      DocumentAuditingEnabled
      HasFullTextSupport
    }
  }
`;

export const GET_ADMIN_SEARCH_CRITERIA = gql`
  query GetAdminSearchCriteria($cookie: String, $cabinetId: String) {
    getAdminSearchCriteria(cookie: $cookie, cabinetId: $cabinetId) {
      id
      DBFieldName
      DisplayName
    }
  }
`;

export const GET_AUTO_COMPLETE_SEARCH = gql`
query getAutoCompleteSearch($cookie: String, $cabinetId: String, $nameField: String) {
  getAutoCompleteSearch(cookie: $cookie, cabinetId: $cabinetId, nameField: $nameField)
}
`;
export const GET_FULL_TREE_VALUES = gql`
query getFullTreeValues($cookie: String, $cabinetId: String) {
  getFullTreeValues(cookie: $cookie, cabinetId: $cabinetId) {
    label
    children {
      label
      children {
        label
      }
    }
  }
}
`;