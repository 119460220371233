import React from 'react';
import { CardActions, Stack, Button, Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';

const CustomCardAction = ({ activeStep, stepsObject, handleBack, isNextDisabled, formik, isEditMode = false, handleAddLoop, isAddLoopDisabled }) => {
  return (
    <CardActions
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        sx={{
          padding: '1.4rem 2rem',
          justifyContent: 'flex-end',
        }}
      >
        {activeStep !== 0 ? (
          <Stack
            spacing={3}
            sx={{
              padding: '1.4rem 2rem',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Button disabled={isEditMode && activeStep === 1 ? true : false} onClick={handleBack} variant="contained">
              Précèdent
            </Button>
          </Stack>
        ) : null}
        {activeStep === 2 ? (
          <Stack
            spacing={3}
            sx={{
              padding: '1.4rem 2rem',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Button
              disabled={isAddLoopDisabled}
              onClick={handleAddLoop}
              sx={{ justifyContent: 'flex-end' }}
              variant="contained"
            >
              Créer un nouveau filtre
            </Button>
          </Stack>
        ) : null}
        <Stack
          spacing={3}
          sx={{
            padding: '1.4rem 2rem',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <Button
            disabled={isNextDisabled}
            onClick={formik.handleSubmit}
            sx={{ justifyContent: 'flex-end' }}
            variant="contained"
          >
            {isEditMode && activeStep === stepsObject.length - 1
              ? 'Mettre à jour'
              : activeStep === stepsObject.length - 1
                ? 'Terminer'
                : 'Suivant'}
          </Button>
        </Stack>

        {formik.errors.cabinetList ? (
          <Typography color="error" sx={{ mt: 3 }} variant="body2">
            {formik.errors.cabinetList}
          </Typography>
        ) : null}
      </Stack>
      <Divider />
    </CardActions>
  );
};

CustomCardAction.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepsObject: PropTypes.array.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleAddLoop: PropTypes.func.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isAddLoopDisabled: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
};

export default CustomCardAction;