// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomDragPreview_root__KTYo8 {
  align-items: "center";
  background-color: #1967d2;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);
  color: #fff;
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 4px 8px;
  pointer-events: none;
}

.CustomDragPreview_icon__yZYFr, .CustomDragPreview_label__FywUD {
  align-items: center;
  display: flex;
}
ul {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Shared/styles/CustomDragPreview.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,6EAA6E;EAC7E,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,QAAQ;EACR,gCAAgC;EAChC,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".root {\n  align-items: \"center\";\n  background-color: #1967d2;\n  border-radius: 4px;\n  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);\n  color: #fff;\n  display: inline-grid;\n  font-size: 14px;\n  gap: 8px;\n  grid-template-columns: auto auto;\n  padding: 4px 8px;\n  pointer-events: none;\n}\n\n.icon, .label {\n  align-items: center;\n  display: flex;\n}\nul {\n  list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CustomDragPreview_root__KTYo8`,
	"icon": `CustomDragPreview_icon__yZYFr`,
	"label": `CustomDragPreview_label__FywUD`
};
export default ___CSS_LOADER_EXPORT___;
