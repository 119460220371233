// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNode_root__ts8ko {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  height: 32px;
  padding-inline-end: 8px;
  width: 250px;
}

.CustomNode_expandIconWrapper__BwTPq {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.CustomNode_expandIconWrapper__BwTPq.CustomNode_isOpen__0yJxI {
  transform: rotate(90deg);
}

.CustomNode_labelGridItem__C23-\\+ {
  padding-inline-start: 8px;
}

.CustomNode_actionButton__\\+80dQ {
  padding: 0 4px;
}
.CustomNode_ul__c7Pcr {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Shared/styles/CustomNode.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8CAA8C;EAC9C,YAAY;EACZ,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".root {\n  align-items: center;\n  display: grid;\n  grid-template-columns: auto auto 1fr auto auto;\n  height: 32px;\n  padding-inline-end: 8px;\n  width: 250px;\n}\n\n.expandIconWrapper {\n  align-items: center;\n  font-size: 0;\n  cursor: pointer;\n  display: flex;\n  height: 24px;\n  justify-content: center;\n  width: 24px;\n  transition: transform linear 0.1s;\n  transform: rotate(0deg);\n}\n\n.expandIconWrapper.isOpen {\n  transform: rotate(90deg);\n}\n\n.labelGridItem {\n  padding-inline-start: 8px;\n}\n\n.actionButton {\n  padding: 0 4px;\n}\n.ul {\n  list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CustomNode_root__ts8ko`,
	"expandIconWrapper": `CustomNode_expandIconWrapper__BwTPq`,
	"isOpen": `CustomNode_isOpen__0yJxI`,
	"labelGridItem": `CustomNode_labelGridItem__C23-+`,
	"actionButton": `CustomNode_actionButton__+80dQ`,
	"ul": `CustomNode_ul__c7Pcr`
};
export default ___CSS_LOADER_EXPORT___;
