/* eslint-disable react/jsx-max-depth */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Drawer,
    Stack,
    useMediaQuery
} from '@mui/material';
import { items } from './config';
import SideNavItem from './SideNavItem';
import { Logo3 } from '../Logo';
import { useAuthContext } from '../../Contexts/auth-context';
import gecinaLogo from '../../assets/Gecina_logo-CMJN.svg';

const SideNav = ({ open, onClose }) => {
    const { isAdmin } = useAuthContext();
    const pathname = useLocation();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [authorizedItems, setAuthorizedItems] = useState([]);
    const setPrivilegedNavItems = () => {
        return isAdmin ? setAuthorizedItems(items) : setAuthorizedItems(items.filter(el => el.isUserTool && !isAdmin));
    };

    useEffect(() => {
        setPrivilegedNavItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box sx={{ p: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component={Link}
                        to="/"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 64,
                            width: '100%',
                            pb: 2,
                        }}
                    >
                        <Box
                            component="img"
                            src={gecinaLogo}
                            alt="Gecina Logo"
                            sx={{
                                height: 43.5,
                                width: 'auto',
                                mr: 2
                            }}
                        />
                        <Logo3 />
                    </Box>
                </Box>
            </Box>
            <Divider sx={{ borderColor: 'neutral.700' }} />
            <Box
                component="nav"
                sx={{
                    flexGrow: 1,
                    px: 2,
                    py: 3
                }}
            >
                <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                        listStyle: 'none',
                        p: 0,
                        m: 0
                    }}
                >
                    {authorizedItems.map((item) => {
                        const active = item.path ? (pathname === item.path) : false;

                        return (
                            <SideNavItem
                                active={active}
                                disabled={item.disabled}
                                external={item.external}
                                icon={item.icon}
                                key={item.title}
                                path={item.path}
                                title={item.title}
                            />
                        );
                    })}
                </Stack>
            </Box>
            <Divider sx={{ borderColor: 'neutral.700' }} />
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280
                    }
                }}
                anchor="left"
                open
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.800',
                    color: 'common.white',
                    width: 280
                }
            }}
            anchor="left"
            onClose={onClose}
            open={open}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

SideNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
export default SideNav;
