/* eslint-disable react/jsx-max-depth */
import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton from MUI
import { useQuery } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';
import { useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { GET_MY_EXTRACTIONS } from '../Graphql/Query/get-myextractions-query';
import useAuth from '../Hooks';
import { useDeleteExtraction } from '../Hooks/use-deleteextraction';
import SearchComponent from '../Components/Shared/Search';
import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
//import the hook here
const Page = () => {
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    const successMsg = location.state?.successMessage;

    if (successMsg) {
      setSuccessMessage(successMsg);
      setOpenSnackbar(true);
      window.history.replaceState({}, document.title);
    }
  }, [location]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, error, data, refetch } = useQuery(GET_MY_EXTRACTIONS, {
    variables: { userId: user?.id },
    fetchPolicy: 'network-only',
  });
  const handleRefresh = () => {
    refetch();
  };
  const extractions = data?.getMyExtractions || [];

  const { handleDelete } = useDeleteExtraction();
  const getStatusIcon = (status) => {
    if (status === 'success') {
      return <CheckCircleOutlineIcon style={{ color: 'green' }} />;
    } else if (status === 'pending' || status === 'new') {
      return <PauseCircleOutlineIcon style={{ color: 'gray' }} />;
    } else if (status === 'failure') {
      return <HighlightOffIcon style={{ color: 'red' }} />;
    } else if (status === 'in progress') {
      return <PauseCircleOutlineIcon style={{ color: 'orange' }} />;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  function sortExtractions(extractions, order) {
    return [...extractions].sort((extraction1, extraction2) => {
      const date1 = new Date(extraction1.create_at);
      const date2 = new Date(extraction2.create_at);
      if (order === 'asc') {
        return date1.getTime() - date2.getTime();
      } else {
        return date2.getTime() - date1.getTime();
      }
    });
  }
  if (loading) {
    return (
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Skeleton height={40} variant="text" width="20%" />
              </div>
              <div>
                <Skeleton height={50} variant="rectangular" width="100%" />
              </div>
              <div>
                <Skeleton height={400} variant="rectangular" width="100%" />
              </div>
            </Stack>
          </Container>
        </Box>
      </DashboardLayout>
    );
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4">Mes Extractions</Typography>
                <IconButton onClick={handleRefresh} aria-label="refresh" size="small" sx={{ ml: 1 }}>
                  <RefreshIcon />
                </IconButton>
              </div>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: 'orange',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <WarningIcon sx={{ mr: 1, fontSize: 'large' }} />
                Vos extractions ci-dessous sont supprimées tous les 15 jours. Pensez bien à les télécharger sur votre réseau.
              </Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid lg={12} md={12} xs={12}>
                  <div>
                    <SearchComponent handleSearchChange={handleSearchChange} title="recherche par titre d’extraction..." />
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Titre</TableCell>
                          <TableCell>
                            <TableSortLabel active direction={order} onClick={handleSort}>
                              Date
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Zip</TableCell>
                          <TableCell>Journal</TableCell>
                          <TableCell>Supprimer</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortExtractions(extractions, order)
                          .filter((extraction) => {
                            if (searchTerm === '') {
                              return true;
                            }
                            return extraction.title.includes(searchTerm);
                          })
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((extraction) => (
                            <TableRow key={extraction.id}>
                              <TableCell>{extraction.title}</TableCell>
                              <TableCell>{extraction.create_at}</TableCell>
                              <TableCell>{getStatusIcon(extraction.status)}</TableCell>
                              <TableCell>
                                {extraction.status === 'success' && extraction.zip_link ? (
                                  <Link aria-label="Zip file link" href={extraction.zip_link} title="Zip file link">
                                    <FolderZipIcon />
                                  </Link>
                                ) : (
                                  ' - '
                                )}
                              </TableCell>
                              <TableCell>
                                {(extraction.status === 'success' || extraction.status === 'failure') &&
                                  extraction.log_link ? (

                                  <a download href={extraction.log_link} >
                                    <ArticleIcon />
                                  </a>
                                ) : (
                                  ' - '
                                )}
                              </TableCell>
                              <TableCell>
                                <IconButton color="secondary" onClick={() => handleDelete(extraction.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={extractions.length}
                      labelRowsPerPage="Ligne par page:"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%', fontSize: '1.5em' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </DashboardLayout >
  );
};

export default Page;
