/* eslint-disable react/jsx-max-depth */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import {
    Avatar,
    Box,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AccountPopover } from './AccountPopover';
import { usePopover } from '../../Hooks/use-popover';
import { useAuthContext } from '../../Contexts/auth-context';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const TopNav = ({ onNavOpen }) => {
    const { isAdmin } = useAuthContext();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const accountPopover = usePopover();

    return (
        <>
            <Box
                component="header"
                sx={{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                    position: 'sticky',
                    left: {
                        lg: `${SIDE_NAV_WIDTH}px`
                    },
                    top: 0,
                    width: {
                        lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
                    },
                    zIndex: (theme) => theme.zIndex.appBar
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        minHeight: TOP_NAV_HEIGHT,
                        px: 2
                    }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                    >
                        {!lgUp ? <IconButton onClick={onNavOpen}>
                            <SvgIcon fontSize="small">
                                <Bars3Icon />
                            </SvgIcon>
                        </IconButton> : null}
                        <Tooltip title="Search">
                            <IconButton>
                                <SvgIcon fontSize="small">
                                    <MagnifyingGlassIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                    >
                        {isAdmin ? <Tooltip title="Users">
                            <Link to='/users'>

                                <IconButton>
                                    <SvgIcon fontSize="small">
                                        <UsersIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Link>
                        </Tooltip> : null}
                        <Avatar
                            onClick={accountPopover.handleOpen}
                            ref={accountPopover.anchorRef}
                            src="/assets/avatars/avatar-anika-visser.png"
                            sx={{
                                cursor: 'pointer',
                                height: 40,
                                width: 40
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
            <AccountPopover
                anchorEl={accountPopover.anchorRef.current}
                onClose={accountPopover.handleClose}
                open={accountPopover.open}
            />
        </>
    );
};

TopNav.propTypes = {
    onNavOpen: PropTypes.func
};

export default TopNav;