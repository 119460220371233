import { gql } from '@apollo/client';

export const GET_MODELS = gql`
  query GetModels {
    getModels {
      id_model
      title
      filecabinetId
      jsonarrayfilter {
        id
        Condition {
          DBName
          Value
          mandatory
        }
        Operation
      }
      loops {
        title
        condition {
          id
          Condition {
            DBName
            Value
            mandatory
          }
          Operation
        }
        treeData {
          id
          parent
          droppable
          DBFieldName
          text
        }
      }
    }
  }
`;
