import { gql } from '@apollo/client';

const DELETE_EXTRACTION = gql`
  mutation DeleteExtraction($id: Int!, $create_at: String!) {
    deleteExtraction(id: $id, create_at: $create_at) {
      id
      user_id
      create_at
      status
      
    }
  }
`;

export default DELETE_EXTRACTION;
