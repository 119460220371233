/* eslint-disable react/jsx-max-depth */
import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';


const Page = () => {
    
    return (
        <DashboardLayout>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth="lg">
                    <Stack spacing={3}>
                        <div>
                            <Typography variant="h4">
                                Account
                            </Typography>
                        </div>
                        <div>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    lg={4}
                                    md={6}
                                    xs={12}
                                />
                                <Grid
                                    lg={8}
                                    md={6}
                                    xs={12}
                                />
                            </Grid>
                        </div>
                    </Stack>
                </Container>
            </Box>
        </DashboardLayout>

    );
};
export default Page;
