// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddDialog_content__xhh8F {
  display: grid;
  gap: 17px;
}

.AddDialog_select__1le5w {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Shared/styles/AddDialog.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;AACb","sourcesContent":[".content {\r\n  display: grid;\r\n  gap: 17px;\r\n}\r\n\r\n.select {\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `AddDialog_content__xhh8F`,
	"select": `AddDialog_select__1le5w`
};
export default ___CSS_LOADER_EXPORT___;
