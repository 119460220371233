/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-no-leaked-render */
import { Stack, MenuItem, Select, TextField, SvgIcon, Box, FormGroup, InputLabel, Typography, Checkbox } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { DateInputs } from './DateInputs';
import dayjs from 'dayjs';
import { useGetAutocompleteSearch } from '../../Hooks/use-Docuware';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const getFilterType = (filter, operator) => {
  if (operator === 'contains') {
    return filter.includes(',') ? filter.split(',').map(el => `${el}*`) : `${filter}*`;
  }
  return filter.includes(',') ? filter.split(',') : filter;
};

const useOperators = (displayTextLabel = '', useDropdown = true, searchCriteria, searchFields) => {
  const [operators, setOperators] = useState([
    { key: 'equalTo', value: 'equalTo', label: 'est égal à' },
    { key: 'contains', value: 'contains', label: 'contient' },
  ]);

  useEffect(() => {
    let selectedDisplayName;
    if (useDropdown) {
      const selectedCriteria = searchFields?.find(field => field.DBFieldName === searchCriteria);
      selectedDisplayName = selectedCriteria?.DisplayName;
    } else {
      selectedDisplayName = displayTextLabel;
    }
    if (selectedDisplayName?.toLowerCase().includes('date')) {
      setOperators([
        { key: 'equalTo', value: 'equalTo', label: 'est égal à' },
        { key: 'between', value: 'between', label: 'entre' },
        { key: 'inPrevious', value: 'inPrevious', label: 'Au cours des précédents' },
      ]);
    } else {
      setOperators([
        { key: 'equalTo', value: 'equalTo', label: 'est égal à' },
        { key: 'contains', value: 'contains', label: 'contient' },
      ]);
    }
  }, [displayTextLabel, useDropdown, searchCriteria, searchFields]);

  return operators;
};

const useAutocomplete = (cabinetId, searchCriteria, useDropdown, displayText) => {
  const { data, loading, error } = useGetAutocompleteSearch(Cookies.get('access_token'), cabinetId, useDropdown ? searchCriteria : displayText);
  const [autocomplete, setAutocomplete] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      setAutocomplete(data.getAutoCompleteSearch);
    } else {
      setAutocomplete([]);
    }
  }, [loading, data, error]);

  return { autocomplete, loading, error };
};



const SearchCriteriaLine = ({
  showMandatoryCheckbox = false,
  showUserDefinedCheckbox = false,
  setCondition = () => ({}),
  condition = [],
  searchFields,
  handleAddFieldValue = () => ({}),
  displayIcon = true,
  useDropdown = true,
  mandatoryText = false,
  displayText = false,
  displayTextLabel = '',
  optionalInput = true,
  setFormValidity,
  cabinetId = '',
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  mandatoryFieldsPage = false,
  handleSetCondition
}) => {
  const [timeValue, setTimeValue] = useState(1);
  const [timeUnit, setTimeUnit] = useState('Mois');

  const timeUnits = ['Mois', 'Années'];


  const calculateDateRange = () => {
    const endDate = dayjs().endOf('day');
    let startDate;

    switch (timeUnit) {
      case 'Mois':
        startDate = endDate.subtract(timeValue, 'month').startOf('day');;
        break;
      case 'Années':
        startDate = endDate.subtract(timeValue, 'year').startOf('day');;
        break;
      default:
        startDate = endDate.startOf('day');;
    }

    return [startDate.format('YYYY-MM-DD HH:mm:ss'), endDate.format('YYYY-MM-DD HH:mm:ss')];
  };
  const formik = useFormik({
    initialValues: {
      searchCriteria: useDropdown ? '' : displayText,
      conditionOperator: '',
      filter: '',
      startDate: startDate || null,
      endDate: endDate || null,
      mandatory: null,
      mandatoryFieldsPage: mandatoryFieldsPage,
      userDefined: false,
    },
    validationSchema: Yup.object().shape({
      searchCriteria: useDropdown
        ? Yup.mixed()
          .required('Search criteria is required')
          .oneOf([...searchFields?.map((field) => field.DBFieldName)])
        : Yup.mixed(),
    }),
    onSubmit: ({ searchCriteria, conditionOperator, filter, mandatory }, helpers) => {
      const selectedCondition = condition.find(cond => cond.DBName === formik.values.searchCriteria);

      if (selectedCondition && selectedCondition.Value.length > 0 && !filter.trim()) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ filter: 'Filter is required' });
        helpers.setSubmitting(false);
        if (setFormValidity) {
          setFormValidity(false);
        }
        return;
      }
      if (!optionalInput && mandatory && (!filter || filter.trim() === '')) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ filter: 'Filter is required' });
        helpers.setSubmitting(false);
        if (setFormValidity) {
          setFormValidity(false);
        }
        return;
      }
      try {
        const selectedCriteria = useDropdown ? searchCriteria : displayText;
        let filterValue;

        if (selectedCriteria.toLowerCase().includes('date')) {
          if (conditionOperator === 'between' && startDate && endDate) {
            filterValue = [
              dayjs(startDate).format('YYYY-MM-DD'),
              dayjs(endDate).format('YYYY-MM-DD')
            ];
          } else if (conditionOperator === 'inPrevious') {
            filterValue = calculateDateRange();
          } else {
            filterValue = [dayjs(filter).format('YYYY-MM-DD')];
          }

          if (!useDropdown) {
            handleAddFieldValue(selectedCriteria, filterValue);
          } else {
            handleSetCondition(selectedCriteria, filterValue, mandatory);
          }
        } else {
          if (useDropdown) {
            filterValue = [filter.split(',').map(v => v.trim()).join(' OR ')];
            formik.setFieldValue('filter', Array.isArray(filterValue) ? filterValue.join(',') : filterValue[0]);
            handleSetCondition(selectedCriteria, filterValue, mandatory);
          } else {
            filterValue = [filter.trim()];
          }
        }

        if (!useDropdown && !selectedCriteria.toLowerCase().includes('date')) {
          handleAddFieldValue(selectedCriteria, filterValue);
        }

        if (useDropdown) {
          // Additional logic for Tree component
          setCondition((prevState) => {
            if (prevState.length > 0 && !filterValue.length) {
              return prevState;
            }
            const existingCondition = prevState.find(cond => cond.DBName === selectedCriteria);
            if (existingCondition) {
              if (selectedCriteria.toLowerCase().includes('date')) {
                existingCondition.Value = filterValue;
              } else {
                existingCondition.Value = [
                  existingCondition.Value[0] + ' OR ' + filterValue[0]
                ];
              }
              return [...prevState];
            }
            return [
              ...prevState,
              {
                DBName: selectedCriteria,
                Value: filterValue,
                mandatory: mandatoryFieldsPage ? null : mandatory,
              },
            ];
          });
        }

        formik.setFieldValue('filter', '');
        formik.setFieldValue('conditionOperator', '');
        if (setStartDate && setEndDate) {
          setStartDate(null);
          setEndDate(null);
        }
        if (setFormValidity && !optionalInput) {
          setFormValidity(true);
        }
        formik.setFieldValue('mandatory', false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  const operators = useOperators(displayTextLabel, useDropdown, formik.values.searchCriteria, searchFields);
  const { autocomplete } = useAutocomplete(cabinetId, formik.values.searchCriteria, useDropdown, displayText);

  useEffect(() => {
    if (!formik.values.userDefined) {
      formik.setFieldValue('mandatory', null);
    } else if (formik.values.userDefined && formik.values.mandatory === null) {
      formik.setFieldValue('mandatory', false);
    }
  }, [formik.values.userDefined, formik.values.mandatory]);

  return (
    <Box sx={{ p: 3, justifyContent: 'center', alignItems: 'center' }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={8} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          {useDropdown ? (
            <FormGroup>
              <InputLabel id="selectSearch">Choisir une métadonnée</InputLabel>
              <Select
                error={!!(formik.touched.searchCriteria && formik.errors.searchCriteria)}
                id="searchCriteria"
                name="searchCriteria"
                onChange={(event) => {
                  formik.handleChange(event);
                  formik.setFieldValue('filter', '');
                  formik.setFieldValue('conditionOperator', '');
                }}
                value={formik.values.searchCriteria}
              >
                {searchFields?.map((el) => (
                  <MenuItem key={uuidv4()} value={el.DBFieldName}>
                    {el.DisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          ) : (
            <Typography sx={{ mt: 1 }} variant="body1">
              {displayTextLabel}{mandatoryText ? '*' : ''}
            </Typography>
          )}
          {!showMandatoryCheckbox ? (

            <><FormGroup>
              <InputLabel id="selectOperator">Choisir un opérateur</InputLabel>
              <Select
                error={!!(formik.touched.conditionOperator && formik.errors.conditionOperator)}
                id="conditionOperator"
                name="conditionOperator"
                onChange={formik.handleChange}
                placeholder="choisir un opérateur"
                value={formik.values.conditionOperator}
              >
                {operators.map((operator) => (
                  <MenuItem key={operator.key} value={operator.value}>
                    {operator.label}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
              <FormGroup>
                <InputLabel id="selectSearch">Valeur(s) </InputLabel>
                {formik.values.conditionOperator === 'between' ? (
                  <DateInputs
                    startDate={startDate}
                    setStartDate={(date) => {
                      setStartDate(dayjs(date).format('MM-DD-YYYY'));
                      //formik.setFieldValue('startDate', dayjs(date).format('MM-DD-YYYY'));
                    }}
                    endDate={endDate}
                    setEndDate={(date) => {
                      setEndDate(dayjs(date).format('MM-DD-YYYY'));
                      //formik.setFieldValue('endDate', dayjs(date).format('MM-DD-YYYY'));
                    }}
                  />
                ) : formik.values.conditionOperator === 'inPrevious' ? (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      type="number"
                      value={timeValue}
                      onChange={(e) => setTimeValue(Math.max(1, parseInt(e.target.value)))}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          style: {
                            textAlign: 'center',
                            fontSize: '1rem',
                            padding: '6px 0',
                          }
                        },

                      }}
                      sx={{
                        width: '60px'
                      }}
                    />
                    <Select
                      value={timeUnit}
                      onChange={(e) => setTimeUnit(e.target.value)}
                    >
                      {timeUnits.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                ) : (
                  <Autocomplete
                    sx={{ width: '250px' }}
                    autoComplete
                    error={formik.touched.filter && formik.errors.filter ? 'true' : undefined}
                    freeSolo
                    id='filter'
                    name='filter'
                    onChange={(event, value) => {
                      formik.setFieldValue('filter', value);

                    }}
                    onInputChange={(event, value) => {
                      formik.setFieldValue('filter', value);

                    }}
                    options={autocomplete?.map((option) => option)}
                    placeholder='entrez vos critères ici ...'
                    renderInput={(params) => {
                      return (<TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        variant='outlined'

                      />);
                    }}


                    value={formik.values.filter} />
                )}
              </FormGroup></>

          ) : null}

          {displayIcon ? (
            <SvgIcon
              fontSize="small"
              onClick={() => {
                formik.handleSubmit();
              }} sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <AddCircleOutlineIcon />
            </SvgIcon>
          ) : null}
        </Stack>
        {formik.errors.submit ? (
          <Typography color="error" sx={{ mt: 3 }} variant="body2">
            {formik.errors.submit}
          </Typography>
        ) : null}
      </Stack>
    </Box>
  );
};

SearchCriteriaLine.propTypes = {
  setCondition: PropTypes.func,
};
export default SearchCriteriaLine;
