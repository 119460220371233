/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable quotes */
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
} from '@mui/material';

import Alert from '@mui/material/Alert';

import dayjs from 'dayjs';
import React from 'react';
import CriteriaChip from '../Components/ModelTree/CriteriaChip';
import { useLazyQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_SEARCH_CRITERIA } from '../Graphql/Query/get-searchcriteria-query';
import { GET_ACCESSIBLE_MODELS } from '../Graphql/Query/get-accessiblemodels';
import ADD_EXTRACTION from '../Graphql/Mutation/add-extraction-mutation';
import UPDATE_EXTRACTION_STATUS from '../Graphql/Mutation/update-extractionstatus-mutation';
import SearchCriteriaLine from '../Components/ModelTree/SearchCriteriaLine';
import useAuth from '../Hooks';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useAdminSearchCriteria } from '../Hooks/use-Docuware';
const NewExtraction = () => {
  const navigate = useNavigate();
  const parsed_cookie = Cookies.get('access_token');
  const { user } = useAuth();
  const [selectedModel, setSelectedModel] = useState(null);
  const [newJsonFilter, setNewJsonFilter] = useState([]);
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectKey, setSelectKey] = useState(0);
  const [isSearchCriteriaLineValid, setIsSearchCriteriaLineValid] = useState(false);
  const [hasValidConditions, setHasValidConditions] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [modelCabinetId, setmodelCabinetId] = useState('');
  const [chipStates, setChipStates] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loops, setLoops] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [extractionId, setExtractionId] = useState(null);

  const escapeSpecialCharacters = (value) => {
    return value.replace(/([()<>&=\\])/g, (match, p1) => {
      // Check if the character is already escaped
      if (match.startsWith('\\')) {
        // If the character is already escaped, return it as is
        return match;
      }
      // Otherwise, escape the character
      return `\\${p1}`;
    });
  };
  const [addExtraction] = useMutation(ADD_EXTRACTION);
  const [updateExtractionStatus] = useMutation(UPDATE_EXTRACTION_STATUS);
  const {
    loading: modelsLoading,
    error: modelsError,
    data: modelsData,
    refetch: refetchModelsData,
  } = useQuery(GET_ACCESSIBLE_MODELS, {
    variables: { cookie: Cookies.get('access_token'), userId: user?.id },
    fetchPolicy: 'no-cache',
  });

  const { loading: adminSearchLoading, error: adminSearchError, data: adminSearchData } = useAdminSearchCriteria(
    Cookies.get('access_token'),
    selectedModel?.filecabinetId || ''
  );

  const displayNameMap = useMemo(() => {
    const map = {};
    if (adminSearchData && adminSearchData.getAdminSearchCriteria) {
      for (const field of adminSearchData.getAdminSearchCriteria) {
        map[field.DBFieldName] = field.DisplayName;
      }
    }
    return map;
  }, [adminSearchData]);






  useEffect(() => {
    if (selectedModel) {
      const initialChipStates = {};
      selectedModel.jsonarrayfilter.forEach((field) => {
        field.Condition.forEach((condition) => {
          if (condition.mandatory === null) {
            initialChipStates[condition.DBName] = { isChipMasked: true };
          }
        });
      });
      setChipStates(initialChipStates);
      setmodelCabinetId(selectedModel.filecabinetId);
      setLoops(selectedModel.loops);
      setNewJsonFilter([...selectedModel.jsonarrayfilter]);
    }
  }, [selectedModel]);


  useEffect(() => {
    const validConditions = newJsonFilter
      .flatMap((field) => field.Condition)
      .filter(
        (condition) =>
          condition.DBName &&
          condition.mandatory &&
          ((Array.isArray(condition.Value) && condition.Value.every((value) => typeof value === 'string' && value.trim().length === 0)))
      );
    const hasValid = validConditions.length > 0;
    setHasValidConditions(hasValid);

    setIsButtonEnabled(
      title.length > 0 &&
      selectedModel !== null &&
      !hasValid
    );
  }, [selectedModel, isSearchCriteriaLineValid, newJsonFilter, title]);


  const handleModelSelect = async (event) => {
    const model = modelsData.getAccessibleModels.find((m) => m.id_model === event.target.value);
    if (model) {
      setSelectedModel(model);


    } else {
      // Reset state variables if no model is selected
      setSelectedModel(null);
      setNewJsonFilter([]);
      setIsButtonEnabled(false);
    }
  };

  const createNewCondition = (fieldName, fieldValue) => ({
    DBName: fieldName,
    Value: fieldValue[0] === null
      ? [null]
      : fieldName.toLowerCase().includes('date') && fieldValue.length === 2 ? fieldValue : [escapeSpecialCharacters(fieldValue[0])],
    mandatory: null
  });

  // Helper function to update the value, handling dates and OR concatenation
  const updateValue = (existingValue, newValue, fieldName) => {
    if (fieldName.toLowerCase().includes('date') && newValue.length === 2) {
      return newValue;
    } else {
      const cleanNewValue = newValue[0];
      if (!existingValue || existingValue.trim() === '') {
        return cleanNewValue;
      } else {
        const existingValues = existingValue.split(' OR ');
        if (!existingValues.includes(cleanNewValue)) {
          return escapeSpecialCharacters(`${existingValue} OR ${cleanNewValue}`);
        }
        return existingValue;
      }
    }
  };

  const updateConditionWithNewValue = (field, fieldName, fieldValue) => {
    const existingConditionIndex = field.Condition.findIndex(c => c.DBName === fieldName);

    if (existingConditionIndex !== -1) {
      // Update existing condition
      const updatedConditions = field.Condition.map((condition, index) => {
        if (index === existingConditionIndex) {
          return {
            ...condition,
            Value: (fieldName.toLowerCase().includes('date') && fieldValue.length === 2) ? updateValue(condition.Value[0], fieldValue, fieldName) : [updateValue(condition.Value[0], fieldValue, fieldName)]
          };
        }
        return condition;
      });
      return { ...field, Condition: updatedConditions };
    } else {
      // Add new condition
      return {
        ...field,
        Condition: [...field.Condition, createNewCondition(fieldName, fieldValue)]
      };
    }
  };

  const processDateCondition = (condition) => {
    if (condition.DBName.toLowerCase().includes('date')) {
      const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
      if (Array.isArray(condition.Value) && condition.Value.length === 2) {
        const [startDate, endDate] = condition.Value;
        if (dateTimeRegex.test(startDate) && dateTimeRegex.test(endDate)) {
          const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day');
          const newEndDate = dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
          const newStartDate = dayjs(newEndDate).subtract(diffInDays, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
          return {
            ...condition,
            Value: [newStartDate, newEndDate]
          };
        }
      }
    }
    return condition;
  };

  const updateLoopsWithNewCriteria = (prevLoops, fieldName, fieldValue) => {
    return prevLoops.map((loop) => ({
      ...loop,
      condition: loop.condition.flatMap((conditionGroup) => {
        if (conditionGroup.Operation === "AND") {
          // Process AND operations first
          const existingConditionIndex = conditionGroup.Condition.findIndex(
            (cond) => cond.DBName === fieldName
          );
          if (existingConditionIndex !== -1) {
            // Update the existing condition
            return {
              ...conditionGroup,
              Condition: conditionGroup.Condition.map((cond, index) => {
                if (index === existingConditionIndex) {
                  return {
                    ...processDateCondition(cond),
                    Value: (fieldName.toLowerCase().includes('date') && fieldValue.length === 2)
                      ? updateValue(cond.Value[0], fieldValue, fieldName)
                      : [updateValue(cond.Value[0], fieldValue, fieldName)],
                  };
                }
                return processDateCondition(cond);
              }),
            };
          } else {
            // Add the new condition
            return {
              ...conditionGroup,
              Condition: [
                ...conditionGroup.Condition.map(processDateCondition),
                createNewCondition(fieldName, fieldValue),
              ],
            };
          }
        } else if (conditionGroup.Operation === "OR") {
          // Process OR operations after AND
          return conditionGroup.Condition.map((condition) => ({
            ...conditionGroup,
            Operation: "AND", // Change operation to AND
            Condition: [
              condition,
              createNewCondition(fieldName, fieldValue),
            ],
          }));
        }
        return conditionGroup;
      }),
    }));

  };



  const handleAddMandatoryFieldValue = (fieldName, fieldValue, fieldIndex) => {
    if (fieldName && fieldValue) {
      setNewJsonFilter(prevFilter =>
        prevFilter.map(filter => updateConditionWithNewValue(filter, fieldName, fieldValue))
      );
      setLoops(prevLoops => updateLoopsWithNewCriteria(prevLoops, fieldName, fieldValue));

      setChipStates((prevChipStates) => ({
        ...prevChipStates,
        [`${fieldName} -${fieldIndex} `]: { isChipMasked: false },
      }));
    }
  };




  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };




  const [getSearchCriteria, { loading: searchcriteriaLoading, error: searchcriteriaError, data: searchData }] = useLazyQuery(GET_SEARCH_CRITERIA, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error fetching search criteria:', error);
      setIsSubmitting(false);
      setErrorMessage(`La récupération des critères de recherche a échoué: ${error.message}, veuillez réessayer.`);
    },
    onCompleted: async (data) => {
      try {

        const { data: updateStatusData } = await updateExtractionStatus({
          variables: { id: extractionId, newStatus: 'new' },
        });
        setIsSubmitting(false);
        navigate('/my-extractions', { state: { successMessage: 'Extraction créée avec succès!' } });
      } catch (error) {
        console.error('Failed to update extraction status:', error);
        setIsSubmitting(false);
        setErrorMessage('La mise à jour de l\'extraction a échoué, veuillez réessayer.');
      }
    },
  });
  const handleGenerateZip = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    // Always call updateLoopsWithNewCriteria with fieldName "CDSDELDOC" and value null
    const updatedLoops = updateLoopsWithNewCriteria(loops, "CDSDELDOC", [null]);
    setLoops(updatedLoops);
    // Create cleanedLoops separately
    const cleanedLoops = updatedLoops.map(loop => ({
      ...loop,
      condition: loop.condition.map(conditionGroup => {
        const { __typename, ...cleanGroup } = conditionGroup;
        return {
          ...cleanGroup,
          Condition: conditionGroup.Condition.map(condition => {
            const { __typename, isMandatory, isOptional, ...cleanCondition } = condition;
            return cleanCondition;
          })
        };
      })
    }));


    const extraction = {
      user_id: user.id,
      user_email: user.email,
      model_id: selectedModel.id_model,
      jsonarrayfilter: selectedModel.jsonarrayfilter,
      loops: cleanedLoops,
      searchresult: [],
      create_at: new Date().toISOString(),
      zip_link: '',
      log_link: '',
      status: 'processing',
      title,
    };
    addExtraction({ variables: { extraction } })
      .then(({ data: extractionData }) => {
        setExtractionId(extractionData.addExtraction.id);
        return extractionData.addExtraction.id;
      })
      .then((newExtractionId) => {
        return getSearchCriteria({
          variables: {
            cookie: parsed_cookie,
            loops: cleanedLoops,
            filecabinetId: selectedModel.filecabinetId,
            extraction_id: newExtractionId,
          },
        });
      })
      .catch((error) => {
        console.error('Error in extraction process:', error);
        setIsSubmitting(false);
        setErrorMessage('La création de l\'extraction a échoué, veuillez réessayer.');
      });
  };

  const [selectedCondition, setSelectedCondition] = useState('');

  const setCondition = (condition) => {
    setSelectedCondition(condition);
  };

  const removeCriteria = (conditionToRemove, fieldIndex) => {
    return setNewJsonFilter((prevJsonFilter) => {
      return prevJsonFilter.map((field, index) => {
        if (index === fieldIndex) {
          return {
            ...field,
            Condition: field.Condition.map((cond) => {
              if (cond.DBName === conditionToRemove.DBName) {
                if (displayNameMap[cond.DBName].toLowerCase().includes('date')) {
                  setStartDate(null);
                  setEndDate(null);
                }
                return { ...cond, Value: [''] };
              }
              return cond;
            }).filter(Boolean),
          };
        }
        return field;
      });
    });
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          {searchcriteriaLoading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={searchcriteriaLoading}
            >
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress color="secondary" /> {/* Change the color here */}
                <Typography variant="h6" sx={{ mt: 2 }}>Creating extraction...</Typography>
              </Box>
            </Backdrop>
          ) : null}
          {errorMessage ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          ) : null}
          <Grid container spacing={3} wrap="wrap">
            <Grid item lg={12} md={12} xs={12}>
              <Typography variant="h6">Choisir un Modèle</Typography>
              <Box sx={{ mt: 3, mb: 3 }}>
                <Select
                  defaultValue="--Select a Model--"
                  key={selectKey}
                  onChange={handleModelSelect}
                  required
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem value="--Select a Model--">--Sélectionner un Modèle--</MenuItem>
                  {modelsData?.getAccessibleModels.map((model) => (
                    <MenuItem key={model.id_model} value={model.id_model}>
                      {model.title}
                    </MenuItem>
                  ))}
                </Select>
                {selectedModel && selectedModel !== '--Select a Model--' && newJsonFilter.length > 0 ? (
                  <Stack direction="row" sx={{ mb: 3, mt: 3 }}>
                    {' '}
                    <Typography variant="h6">Ajouter des critères de recherche pour les champs définis par l’administrateur</Typography>
                  </Stack>
                ) : null}
              </Box>
              {adminSearchData ? (
                newJsonFilter
                  .map((field, fieldIndex) =>
                    field.Condition.map((condition) => ({ condition, fieldIndex }))
                  )
                  .flat()
                  .filter(
                    ({ condition }) =>
                      condition.mandatory !== null &&
                      condition.DBName &&
                      Array.isArray(condition.Value)
                  )
                  .map(({ condition, fieldIndex }, index) => {
                    let valueToDisplay;
                    if (displayNameMap[condition.DBName].toLowerCase().includes('date') && Array.isArray(condition.Value) && condition.Value.length === 2) {
                      valueToDisplay = `${displayNameMap[condition.DBName]}: ${condition.Value[0]} à ${condition.Value[1]}`;
                    } else {
                      valueToDisplay = `${displayNameMap[condition.DBName]}: ${condition.Value[0]?.trim() || ''} `;
                    }

                    return (
                      <React.Fragment key={`fragment--${fieldIndex}-${condition.DBName}-${index}`}>
                        <SearchCriteriaLine
                          showMandatoryCheckbox={false}
                          cabinetId={modelCabinetId}
                          mandatoryText={condition.mandatory}
                          displayText={condition.DBName}
                          displayTextLabel={displayNameMap[condition.DBName] || condition.DBName}
                          handleAddFieldValue={(fieldName, fieldValue) => handleAddMandatoryFieldValue(fieldName, fieldValue, fieldIndex)}
                          key={`jsonField - ${condition.DBName} -${index} `}
                          optionalInput={false}
                          setCondition={setCondition}
                          setFormValidity={setIsSearchCriteriaLineValid}
                          useDropdown={false}
                          setEndDate={setEndDate}
                          setStartDate={setStartDate}
                          startDate={startDate}
                          endDate={endDate}
                        />
                        <CriteriaChip
                          key={`chip - ${condition.DBName} -${index} `}
                          isMandatory={condition.mandatory === true}
                          isOptional={condition.isOptional}
                          removeCriteria={() => removeCriteria(condition, fieldIndex)}
                          valueToDisplay={valueToDisplay}
                          hideFilledMandatory={chipStates[`${condition.DBName} -${fieldIndex} `]?.isChipMasked}
                        />
                      </React.Fragment>
                    );
                  })
              ) : selectedModel ? <CircularProgress /> : null}


              <Divider />




              <Box sx={{ mt: 4, mb: 2 }}>
                <Divider />
              </Box>
              <Typography variant="h6">Ajouter un titre à l’extraction</Typography>
              <Box sx={{ mt: 4, mb: 4 }}>
                <TextField
                  fullWidth
                  label="Title"
                  onChange={handleTitleChange}
                  required
                  value={title}
                  variant="outlined"
                />

              </Box>
              <Stack
                direction="row"
                justifyContent="flex-end" // Align items to the end of the row
                sx={{ mt: 2 }}
              >
                <Button
                  color="primary"
                  disabled={!isButtonEnabled || isSubmitting}
                  onClick={handleGenerateZip}
                  variant="contained"
                >
                  Créer Extraction
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </DashboardLayout>
  );
};

export default NewExtraction;
