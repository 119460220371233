// Tree.js

/* eslint-disable quotes */

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Cookies from 'js-cookie';
import { CircularProgress, Alert, Modal } from '@mui/material';
import { Box } from '@mui/system';
import SearchCriteriaLine from '../ModelTree/SearchCriteriaLine';
import TreeNode from './TreeNode';
import { useGetFullTreeValues } from '../../Hooks';
import { useAdminSearchCriteria } from '../../Hooks/use-Docuware';
import { v4 as uuidv4 } from 'uuid';

const Tree = ({ fileCabinet, setCondition, initialCondition, isEditMode = false, activeStep, loopIndex }) => {

    const { loading, error, data } = useGetFullTreeValues(Cookies.get('access_token'), fileCabinet);

    const [nodes, setNodes] = useState([]);
    const [checkedState, setCheckedState] = useState({});
    const [indeterminateState, setIndeterminateState] = useState({});
    const [nodeCriteria, setNodeCriteria] = useState([]);
    const [currentNodeId, setCurrentNodeId] = useState(null);
    const [currentNodeLabel, setCurrentNodeLabel] = useState(null);
    const [shouldReset, setShouldReset] = useState(false);
    const [open, setOpen] = useState(false);
    const [nullOperationConditions, setNullOperationConditions] = useState([]);

    const escapeSpecialCharacters = (value) => {
        value = Array.isArray(value) ? value[0] : value;
        return value.replace(/([()<>&=\\])/g, (match, p1) => {
            // Check if the character is already escaped
            if (match.startsWith('\\')) {
                // If the character is already escaped, return it as is
                return match;
            }
            // Otherwise, escape the character
            return `\\${p1}`;
        });
    };


    const { loading: fieldsLoading, error: fieldsError, data: fieldsData } = useAdminSearchCriteria(
        Cookies.get('access_token'),
        fileCabinet
    );

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const searchFields = useMemo(() => {
        if (!fieldsData) return [];
        const filteredFields = fieldsData?.getAdminSearchCriteria.filter(field =>
            !['TYPE_DOCUMENT', 'SOUS_CHAPITRE', 'CHAPITRE'].includes(field.DBFieldName)
        );
        return filteredFields || [];
    }, [fieldsData]);

    const displayNameMap = useMemo(() => {
        const map = {};
        if (fieldsData && fieldsData.getAdminSearchCriteria) {
            for (const field of fieldsData.getAdminSearchCriteria) {
                map[field.DBFieldName] = field.DisplayName;
            }
        }
        return map;
    }, [fieldsData]);

    const initializeState = (nodes, parentId = '') => {
        nodes.forEach((node, index) => {
            const nodeId = `${parentId}${parentId ? '-' : ''}${index}`;
            setCheckedState(prevState => ({ ...prevState, [nodeId]: false }));
            setIndeterminateState(prevState => ({ ...prevState, [nodeId]: false }));
            if (node.children) {
                initializeState(node.children, nodeId);
            }
        });
    };

    useEffect(() => {
        setShouldReset(true);

    }, [loopIndex]);
    useEffect(() => {
        if (data?.getFullTreeValues) {
            setNodes(data.getFullTreeValues);
            //initializeState(data.getFullTreeValues);
        }
    }, [data?.getFullTreeValues]);



    useEffect(() => {
        if (nodes.length > 0) {
            let newCheckedState, newIndeterminateState, newNodeCriteria;

            if (shouldReset) {
                // Reset all states
                newCheckedState = {};
                newIndeterminateState = {};
                setNodeCriteria([]);
                newNodeCriteria = [];

            } else {
                newCheckedState = { ...checkedState };
                newIndeterminateState = { ...indeterminateState };
            }
            if (initialCondition.length > 0) {
                // Process AND conditions first to set up nodeCriteria
                const andConditions = initialCondition.filter(item => item.Operation === "AND");
                if (andConditions.length > 0) {
                    newNodeCriteria = mapAddedCriteria(andConditions);
                    setNodeCriteria(newNodeCriteria);
                    // Mark nodes with criteria as indeterminate
                    newNodeCriteria.forEach(({ nodeId }) => {
                        newIndeterminateState[nodeId] = true;
                        newCheckedState[nodeId] = false;
                    });
                }

                const orConditions = initialCondition.filter(item => item.Operation === "OR");
                if (orConditions.length > 0) {
                    preSelectNodes(orConditions, newCheckedState, newIndeterminateState);
                }

                // Update all parent states
                const updateAllParentStates = (nodes, parentId = '') => {
                    nodes.forEach((node, index) => {
                        const nodeId = `${parentId}${parentId ? '-' : ''}${index}`;
                        updateParentStates(nodeId, newCheckedState, newIndeterminateState);
                        if (node.children) {
                            updateAllParentStates(node.children, nodeId);
                        }
                    });
                };

                updateAllParentStates(nodes);
                const nullConditions = initialCondition.filter(item => item.Operation === null);
                setNullOperationConditions(nullConditions);
            }

            setCheckedState(newCheckedState);
            setIndeterminateState(newIndeterminateState);



        }
    }, [loopIndex, activeStep, shouldReset, nodes]);

    useEffect(() => {
        if (nodes.length > 0) {
            const newCondition = buildJsonArrayFilter(checkedState, indeterminateState, nodes);
            updateFinalCondition(newCondition);
        }
    }, [activeStep, checkedState, indeterminateState, nodes]);


    const preSelectNodes = (conditions, newCheckedState, newIndeterminateState) => {

        conditions.forEach(condition => {
            condition.Condition.forEach(cond => {
                const values = cond.Value[0]?.split(' OR ')
                    .map(v => v.replace(/^\*|\*$/g, ''));

                values.forEach(value => {
                    let nodeIds = findNodeIdsByValue(nodes, value, cond.DBName);
                    nodeIds.forEach(nodeId => {
                        newCheckedState[nodeId] = true;
                        newIndeterminateState[nodeId] = false;


                        // Select all children
                        const selectChildren = (currentNodeId) => {
                            const node = findNodeById(nodes, currentNodeId);
                            if (node && node.children) {
                                node.children.forEach((_, childIndex) => {
                                    const childId = `${currentNodeId}-${childIndex}`;
                                    newCheckedState[childId] = true;
                                    newIndeterminateState[childId] = false;
                                    selectChildren(childId);
                                });
                            }
                        };
                        selectChildren(nodeId);

                        updateParentStates(nodeId, newCheckedState, newIndeterminateState);
                    });
                });
            });
        });

        // After marking all nodes, update parent states
        Object.keys(newCheckedState).forEach(nodeId => {
            updateParentStates(nodeId, newCheckedState, newIndeterminateState);
        });

        setCheckedState({ ...newCheckedState });
        setIndeterminateState({ ...newIndeterminateState });

    };


    const findNodeIdsByValue = (nodes, value, dbName, parentId = '') => {
        let nodeIds = [];
        const unescapedValue = value.replace(/\\(.)/g, '$1');
        nodes.forEach((node, index) => {
            const currentId = `${parentId}${index}`;
            const unescapedLabel = node.label.replace(/\\(.)/g, '$1');
            if (
                (dbName === 'CHAPITRE' && unescapedLabel === unescapedValue) ||
                (dbName === 'SOUS_CHAPITRE' && unescapedLabel === unescapedValue) ||
                (dbName === 'TYPE_DOCUMENT' && unescapedLabel === unescapedValue)
            ) {
                nodeIds.push(currentId);
            }

            if (node.children) {
                nodeIds = [...nodeIds, ...findNodeIdsByValue(node.children, value, dbName, `${currentId}-`)];
            }
        });

        return nodeIds;
    };


    const updateParentStates = (nodeId, checkedState, indeterminateState) => {

        const parentId = nodeId?.split('-').slice(0, -1).join('-');
        if (parentId) {
            const parentNode = findNodeById(nodes, parentId);
            if (parentNode) {
                const childrenIds = parentNode.children.map((_, index) => `${parentId}-${index}`);
                const hasChildren = parentNode.children && parentNode.children.length > 0;
                const allChildrenChecked = childrenIds.every(id => checkedState[id]);
                const someChildrenChecked = childrenIds.some(id => checkedState[id]);
                const someChildrenIndeterminate = childrenIds.some(id => indeterminateState[id]);
                const someChildrenWithCriteria = childrenIds.some(id =>
                    nodeCriteria.some(criteria => criteria.nodeId === id)
                );

                if (hasChildren && allChildrenChecked) {

                    checkedState[parentId] = true;
                    indeterminateState[parentId] = false;

                }
                if (hasChildren && !someChildrenChecked && !someChildrenIndeterminate && !someChildrenWithCriteria) {

                    checkedState[parentId] = false;
                    indeterminateState[parentId] = false;
                }
                if (someChildrenIndeterminate || (someChildrenChecked && !allChildrenChecked)) {

                    indeterminateState[parentId] = true;
                    checkedState[parentId] = false;
                } else {
                    checkedState[parentId] = allChildrenChecked;
                    indeterminateState[parentId] = false;
                }

                updateParentStates(parentId, checkedState, indeterminateState);
            }
        } else {

            // Handle the case where the node itself has no parent (i.e., it's a root node)
            const node = findNodeById(nodes, nodeId);
            if (node) {
                const hasChildren = node.children && node.children.length > 0;
                const childrenIds = hasChildren ? node.children.map((_, index) => `${nodeId}-${index}`) : [];
                const allChildrenChecked = childrenIds.every(id => checkedState[id]);
                const someChildrenChecked = childrenIds.some(id => checkedState[id]);
                const someChildrenIndeterminate = childrenIds.some(id => indeterminateState[id]);
                const someChildrenWithCriteria = childrenIds.some(id =>
                    nodeCriteria.some(criteria => criteria.nodeId === id)
                );
                const hasCriteria = nodeCriteria.some(criteria => criteria.nodeId === nodeId);

                if (!hasChildren) {
                    checkedState[nodeId] = false;
                    indeterminateState[nodeId] = false;
                } else if (hasChildren && allChildrenChecked && !hasCriteria) {
                    checkedState[nodeId] = true;
                    indeterminateState[nodeId] = false;
                } else if (hasChildren && !someChildrenChecked && !someChildrenIndeterminate && !someChildrenWithCriteria) {
                    checkedState[nodeId] = false;
                    indeterminateState[nodeId] = false;
                } else if (hasChildren && allChildrenChecked) {
                    checkedState[nodeId] = true;
                    indeterminateState[nodeId] = false;
                } else if (hasChildren && (someChildrenChecked || someChildrenIndeterminate)) {

                    indeterminateState[nodeId] = true;
                    checkedState[nodeId] = false;
                } else {
                    checkedState[nodeId] = false;
                    indeterminateState[nodeId] = false;
                }
            }
        }
    };

    const mapAddedCriteria = (andConditions) => {
        return andConditions.flatMap(condition => {
            // Find the relevant field (TYPE_DOCUMENT, SOUS_CHAPITRE, or CHAPITRE)
            const relevantField = condition.Condition.find(c =>
                c.DBName === "TYPE_DOCUMENT" ||
                c.DBName === "SOUS_CHAPITRE" ||
                c.DBName === "CHAPITRE"
            );

            if (!relevantField) {
                return [];
            }

            const fieldValue = relevantField.Value[0];
            const nodeId = findNodeIdsByValue(nodes, fieldValue, relevantField.DBName)[0];
            const nodeLabel = findNodeById(nodes, nodeId)?.label; // Get the node label

            // Filter out the other criteria that are not the relevant field
            const otherCriteria = condition.Condition.filter(c =>
                c.DBName !== "TYPE_DOCUMENT" &&
                c.DBName !== "SOUS_CHAPITRE" &&
                c.DBName !== "CHAPITRE"
            );

            // If a node ID is found, return an object with the node ID, node label, and other criteria
            return nodeId ? [{
                nodeId,
                nodeLabel,
                criteria: otherCriteria.map(c => ({
                    DBName: c.DBName,
                    Value: c.DBName.toLowerCase().includes('date') ? c.Value : [escapeSpecialCharacters(c.Value)],
                    mandatory: c.mandatory
                }))
            }] : [];
        });
    };


    const buildJsonArrayFilter = useCallback((checkedState, indeterminateState, nodes) => {
        const conditions = {
            CHAPITRE: new Set(),
            SOUS_CHAPITRE: new Set(),
            TYPE_DOCUMENT: new Set(),
        };

        const traverse = (nodes, parentId = '', parentLabels = [], parentChecked = false) => {
            nodes.forEach((node, index) => {
                const nodeId = `${parentId}${parentId ? '-' : ''}${index}`;
                const currentLabels = [...parentLabels, node.label];
                const nodeChecked = checkedState[nodeId];
                const nodeIndeterminate = indeterminateState[nodeId];

                if (nodeChecked && !parentChecked && !nodeIndeterminate) {
                    if (currentLabels.length === 3) {
                        conditions.TYPE_DOCUMENT.add(currentLabels[2]);
                    } else if (currentLabels.length === 2) {
                        conditions.SOUS_CHAPITRE.add(currentLabels[1]);
                    } else if (currentLabels.length === 1) {
                        conditions.CHAPITRE.add(currentLabels[0]);
                    }
                }

                if (node.children && (nodeChecked || nodeIndeterminate)) {
                    traverse(node.children, nodeId, currentLabels, nodeChecked);
                }
            });
        };

        traverse(nodes);

        const filterConditions = [];

        if (conditions.TYPE_DOCUMENT.size > 0) {
            filterConditions.push({
                DBName: 'TYPE_DOCUMENT',
                Value: [escapeSpecialCharacters(Array.from(conditions.TYPE_DOCUMENT).join(' OR '))],
            });
        }

        if (conditions.SOUS_CHAPITRE.size > 0) {
            filterConditions.push({
                DBName: 'SOUS_CHAPITRE',
                Value: [escapeSpecialCharacters(Array.from(conditions.SOUS_CHAPITRE).join(' OR '))],
            });
        }

        if (conditions.CHAPITRE.size > 0) {
            filterConditions.push({
                DBName: 'CHAPITRE',
                Value: [escapeSpecialCharacters(Array.from(conditions.CHAPITRE).join(' OR '))],
            });
        }

        return filterConditions;
    }, [nodes]);


    const removeAllCriteria = (nodeId) => {
        setNodeCriteria(prevCriteria => {
            const updatedCriteria = prevCriteria.filter(node => !node.nodeId.startsWith(nodeId));
            return updatedCriteria;
        });
    };


    const handleCheck = (nodeId, isChecked) => {
        const updatedCheckedState = { ...checkedState };
        const updatedIndeterminateState = { ...indeterminateState };
        const updateNodeAndChildren = (currentNodeId, checked) => {
            updatedCheckedState[currentNodeId] = checked;
            updatedIndeterminateState[currentNodeId] = false;

            const node = findNodeById(nodes, currentNodeId);
            if (node?.children) {
                node.children.forEach((_, index) => {
                    const childId = `${currentNodeId}-${index}`;
                    updateNodeAndChildren(childId, checked);
                });
            }
        };

        if (updatedIndeterminateState[nodeId]) {
            // If the node is in indeterminate state, uncheck it and remove all criteria
            updateNodeAndChildren(nodeId, false);
            removeAllCriteria(nodeId);


        } else {
            updateNodeAndChildren(nodeId, isChecked);
        }

        updateParentStates(nodeId, updatedCheckedState, updatedIndeterminateState);

        setCheckedState(updatedCheckedState);
        setIndeterminateState(updatedIndeterminateState);

        const selectedConditions = buildJsonArrayFilter(updatedCheckedState, updatedIndeterminateState, nodes);
        updateFinalCondition(selectedConditions);
    };

    const findNodeById = (nodes, nodeId) => {
        const nodeIdParts = nodeId?.split('-').map(Number);
        let node = nodes[nodeIdParts?.[0]];
        for (let i = 1; i < nodeIdParts?.length; i++) {
            if (!node || !node.children) return null;
            node = node.children[nodeIdParts[i]];
        }
        return node;
    };

    const handleAddCriteria = (nodeId) => {
        const node = findNodeById(nodes, nodeId);
        if (node) {
            setCurrentNodeId(nodeId);
            setCurrentNodeLabel(node.label);
            setOpen(true);
        }
    };

    const updateFinalCondition = (selectedConditions) => {

        let finalConditionArray = [];

        if (selectedConditions.length > 0) {
            finalConditionArray.push({
                id: uuidv4(),
                Operation: "OR",
                Condition: selectedConditions
            });
        }

        if (nodeCriteria.length > 0) {
            finalConditionArray = [
                ...finalConditionArray,
                ...buildConditionObject(nodeCriteria)
            ];
        }

        finalConditionArray = [
            ...finalConditionArray,
            ...nullOperationConditions
        ];
        setCondition(finalConditionArray);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSetCondition = (nodeId, nodeLabel, selectedCriteria, filterValue, mandatory) => {
        filterValue = selectedCriteria.toLowerCase().includes('date') ? filterValue : escapeSpecialCharacters(filterValue);

        const newCondition = {
            DBName: selectedCriteria,
            Value: selectedCriteria.toLowerCase().includes('date') ? filterValue : [filterValue],
            mandatory: mandatory,
        };

        const updatedNodeCriteria = JSON.parse(JSON.stringify(nodeCriteria));

        const updateCriteria = (nodeId, criteria) => {
            const index = updatedNodeCriteria.findIndex((obj) => obj.nodeId === nodeId);
            if (index !== -1) {
                const criteriaIndex = updatedNodeCriteria[index].criteria.findIndex(c => c.DBName === criteria.DBName);
                if (criteriaIndex !== -1) {
                    // Check if the criteria is inherited from the parent
                    const parentId = nodeId.split('-').slice(0, -1).join('-');
                    const parentCriteriaIndex = updatedNodeCriteria.findIndex(c => c.nodeId === parentId);
                    if (parentCriteriaIndex !== -1) {
                        const parentCriteria = updatedNodeCriteria[parentCriteriaIndex].criteria.find(c => c.DBName === criteria.DBName);
                        if (parentCriteria && parentCriteria.Value[0] === updatedNodeCriteria[index].criteria[criteriaIndex].Value[0]) {
                            // Overwrite the criteria if it's inherited and has the same value
                            updatedNodeCriteria[index].criteria[criteriaIndex].Value = criteria.Value;
                        } else {
                            // Append the criteria with 'OR' if it's not inherited or has a different value
                            updatedNodeCriteria[index].criteria[criteriaIndex].Value = [
                                updatedNodeCriteria[index].criteria[criteriaIndex].Value[0] + ' OR ' + criteria.Value[0]
                            ];
                        }
                    } else {
                        // Append the criteria with 'OR' if there's no parent criteria
                        updatedNodeCriteria[index].criteria[criteriaIndex].Value = [
                            updatedNodeCriteria[index].criteria[criteriaIndex].Value[0] + ' OR ' + criteria.Value[0]
                        ];
                    }
                } else {
                    updatedNodeCriteria[index].criteria.push(criteria);
                }
            } else {
                updatedNodeCriteria.push({ nodeId, nodeLabel, criteria: [criteria] });
            }
        };

        updateCriteria(nodeId, newCondition);

        // Propagate criteria to children
        const propagateCriteriaToChildren = (currentNodeId) => {
            const node = findNodeById(nodes, currentNodeId);
            if (node?.children) {
                node.children.forEach((_, index) => {
                    const childId = `${currentNodeId}-${index}`;
                    const childCriteriaIndex = updatedNodeCriteria.findIndex(c => c.nodeId === childId);
                    if (childCriteriaIndex !== -1) {
                        const criteriaIndex = updatedNodeCriteria[childCriteriaIndex].criteria.findIndex(c => c.DBName === selectedCriteria);
                        if (criteriaIndex !== -1) {
                            // Overwrite the criteria with the full value from the parent
                            const parentCriteriaIndex = updatedNodeCriteria.findIndex(c => c.nodeId === currentNodeId);
                            if (parentCriteriaIndex !== -1) {
                                const parentCriteria = updatedNodeCriteria[parentCriteriaIndex].criteria.find(c => c.DBName === selectedCriteria);
                                if (parentCriteria) {
                                    updatedNodeCriteria[childCriteriaIndex].criteria[criteriaIndex].Value = parentCriteria.Value;
                                }
                            }
                        } else {
                            const parentCriteriaIndex = updatedNodeCriteria.findIndex(c => c.nodeId === currentNodeId);
                            if (parentCriteriaIndex !== -1) {
                                const parentCriteria = updatedNodeCriteria[parentCriteriaIndex].criteria.find(c => c.DBName === selectedCriteria);
                                if (parentCriteria) {
                                    updatedNodeCriteria[childCriteriaIndex].criteria.push(parentCriteria);
                                }
                            }
                        }
                    } else {
                        const parentCriteriaIndex = updatedNodeCriteria.findIndex(c => c.nodeId === currentNodeId);
                        if (parentCriteriaIndex !== -1) {
                            const parentCriteria = updatedNodeCriteria[parentCriteriaIndex].criteria.find(c => c.DBName === selectedCriteria);
                            if (parentCriteria) {
                                updatedNodeCriteria.push({ nodeId: childId, nodeLabel: node.children[index].label, criteria: [parentCriteria] });
                            }
                        }
                    }
                    propagateCriteriaToChildren(childId);
                });
            }
        };

        propagateCriteriaToChildren(nodeId);

        setNodeCriteria(updatedNodeCriteria);

        const updateIndeterminateState = (currentNodeId) => {
            const updatedIndeterminateState = { ...indeterminateState };

            const setNodeAndChildrenIndeterminate = (nodeId) => {
                updatedIndeterminateState[nodeId] = true;
                const node = findNodeById(nodes, nodeId);
                if (node?.children) {
                    node.children.forEach((_, index) => {
                        const childId = `${nodeId}-${index}`;
                        setNodeAndChildrenIndeterminate(childId);
                    });
                }
            };

            setNodeAndChildrenIndeterminate(currentNodeId);
            return updatedIndeterminateState;
        };

        const newIndeterminateState = updateIndeterminateState(nodeId);
        updateParentStates(nodeId, checkedState, newIndeterminateState);

        setIndeterminateState(newIndeterminateState);

        const selectedConditions = buildJsonArrayFilter(checkedState, newIndeterminateState, nodes);
        updateFinalCondition(selectedConditions);
        setStartDate(null);
        setEndDate(null);
        handleClose();
    };














    const buildConditionObject = (updatedNodeCriteria) => {
        const conditionArray = [];

        updatedNodeCriteria.forEach(({ nodeId, nodeLabel, criteria }) => {
            const nodeLevel = nodeId?.split('-').length;
            let conditionObject = {
                id: uuidv4(),
                Operation: "AND",
                Condition: criteria.map(c => ({
                    DBName: c.DBName,
                    Value: c.DBName.toLowerCase().includes('date') ? c.Value : [escapeSpecialCharacters(c.Value)],
                    mandatory: c.mandatory
                })),
            };

            if (nodeLevel === 1) {
                conditionObject.Condition.unshift({
                    DBName: "CHAPITRE",
                    Value: [escapeSpecialCharacters(nodeLabel)],
                });
            } else if (nodeLevel === 2) {
                conditionObject.Condition.unshift({
                    DBName: "SOUS_CHAPITRE",
                    Value: [escapeSpecialCharacters(nodeLabel)],
                });
            } else if (nodeLevel === 3) {
                conditionObject.Condition.unshift({
                    DBName: "TYPE_DOCUMENT",
                    Value: [escapeSpecialCharacters(nodeLabel)],
                });
            }

            conditionArray.push(conditionObject);
        });

        return conditionArray;
    };

    const getAllChildNodeIds = (node, parentId) => {
        if (!node.children || node.children.length === 0) return [];
        let childrenIds = [];

        node.children.forEach((child, index) => {
            const childId = `${parentId}-${index}`;
            childrenIds.push(childId);
            // Recursively find all descendant nodeIds
            childrenIds = childrenIds.concat(getAllChildNodeIds(child, childId));
        });

        return childrenIds;
    };
    const handleDeleteCriteria = (nodeId, criteriaName) => {

        setNodeCriteria(prevCriteria => {

            const updatedCriteria = prevCriteria.map(node => {
                if (node.nodeId === nodeId || node.nodeId.startsWith(`${nodeId}-`)) {

                    return {
                        ...node,
                        criteria: node.criteria.filter(criteria => criteria.DBName !== criteriaName)
                    };
                }
                return node;
            }).filter(node => node.criteria.length > 0);

            if (!updatedCriteria.some(node => node.nodeId === nodeId)) {
                setIndeterminateState(prevState => {
                    const newState = { ...prevState };
                    const node = findNodeById(nodes, nodeId);
                    if (node) {
                        const hasChildren = node.children && node.children.length > 0;
                        const childrenIds = getAllChildNodeIds(node, nodeId);
                        const someChildrenChecked = childrenIds.some(id => checkedState[id]);


                        if (!hasChildren) {
                            newState[nodeId] = false;
                        } else if (hasChildren && someChildrenChecked) {
                            newState[nodeId] = true;
                        } else {
                            newState[nodeId] = false;
                            childrenIds.map((id) => newState[id] = false);
                        }
                    }
                    updateParentStates(nodeId, checkedState, newState);
                    return newState;
                });

            }



            return updatedCriteria;
        });

        // Update final condition
        const selectedConditions = buildJsonArrayFilter(checkedState, indeterminateState, nodes);
        setStartDate(null);
        setEndDate(null);
        updateFinalCondition(selectedConditions);
    };

    useEffect(() => {
        // Check if either checkedState or indeterminateState have some truthy values
        if (Object.values(checkedState).some(Boolean) || Object.values(indeterminateState).some(Boolean)) {
            const selectedConditions = buildJsonArrayFilter(checkedState, indeterminateState, nodes);
            updateFinalCondition(selectedConditions);
        }
    }, [nodeCriteria, checkedState, nodes]);

    return (
        <div>
            {loading ? (
                <Box alignItems="center" display="flex" height="100%" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : null}
            {error ? (
                <Box alignItems="center" display="flex" height="100%" justifyContent="center">
                    <Alert severity="error">Error loading tree data</Alert>
                </Box>
            ) : null}
            {nodes.map((node, index) => (
                <TreeNode
                    key={index}
                    node={node}
                    nodeId={`${index}`}
                    checked={checkedState[`${index}`] || false}
                    indeterminate={indeterminateState[`${index}`] || false}
                    onCheck={handleCheck}
                    onAddCriteria={handleAddCriteria}
                    checkedState={checkedState}
                    indeterminateState={indeterminateState}
                    nodeCriteria={nodeCriteria}
                    displayNameMap={displayNameMap}
                    onDeleteCriteria={handleDeleteCriteria}
                />
            ))}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <SearchCriteriaLine
                        searchFields={searchFields}
                        cabinetId={fileCabinet}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        mandatoryFieldsPage={true}
                        handleSetCondition={(selectedCriteria, filterValue, mandatory) =>
                            handleSetCondition(currentNodeId, currentNodeLabel, selectedCriteria, filterValue, mandatory)}
                        showUserDefinedCheckbox={true}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default Tree;

