import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import DELETE_EXTRACTION from '../Graphql/Mutation/delete-extraction-mutation';
export const useDeleteExtraction = () => {
  const [deleteExtraction] = useMutation(DELETE_EXTRACTION);

  const handleDelete = (id) => {
    deleteExtraction({
      variables: { id },
      update: (cache) => {
        const extractionId = cache.identify({
          __typename: 'Extraction',
          id: id,
        });

        const existingExtraction = cache.readFragment({
          id: extractionId,
          fragment: gql`
            fragment ExistingExtraction on Extraction {
              id
              user_id
              create_at
              status
            }
          `,
        });

        if (existingExtraction) {
          cache.evict({ id: extractionId });
          cache.gc();
        }
      },
    });
  };

  return { handleDelete };
};
