import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, ButtonBase } from '@mui/material';

const SideNavItem = ({ active = false, disabled, external, icon, path, title }) => {


    const linkProps = path
        ? external
            ? {
                component: 'a',
                href: path,
                target: '_blank'
            }
            : {
                component: Link,
                to: path
            }
        : {};

    return (
        <li>
            <ButtonBase
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    pl: '16px',
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    ...(active && {
                        backgroundColor: 'rgba(255, 255, 255, 0.06)'
                    }),
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.06)'
                    }
                }}
                {...linkProps}
            >
                {icon ? <Box
                    component="span"
                    sx={{
                        alignItems: 'center',
                        color: 'neutral.400',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        mr: 2,
                        ...(active && {
                            color: 'primary.main'
                        })
                    }}
                >
                    {icon}
                </Box> : null}
                <Box
                    component="span"
                    sx={{
                        color: 'neutral.400',
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap',
                        ...(active && {
                            color: 'common.white'
                        }),
                        ...(disabled && {
                            color: 'neutral.500'
                        })
                    }}
                >
                    {title}
                </Box>
            </ButtonBase>
        </li>
    );
};

SideNavItem.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    path: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default SideNavItem;