/* eslint-disable react/jsx-max-depth */
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Stack,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import { getInitials } from '../../utils/get-initials';
import TablePagination from '@mui/material/TablePagination';
import SearchComponent from '../Shared/Search';
import { useState } from 'react';
const UserPrivilegeTable = ({
    items = [],
    groups = [],
    onDeselectAll,
    onDeselectOne,
    onSelectAll,
    onSelectOne,
    selected = [],
    count,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange
}) => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const allItems = [...groups, ...items];
    const filteredItems = allItems.filter((item) => {
        return (
            item.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
    const selectedSome = (selected.length > 0) && (selected.length < allItems.length);
    const selectedAll = (allItems.length > 0) && (selected.length === allItems.length);


    const sortedItems = filteredItems.sort((a, b) => {
        const aSelected = selected.includes(a.id || a.Id);
        const bSelected = selected.includes(b.id || b.Id);
        if (aSelected === bSelected) return 0;
        return aSelected ? -1 : 1;
    });
    return (
        <Card>
            <Box sx={{ minWidth: 800 }}>
                <SearchComponent handleSearchChange={handleSearchChange} title="Recherche par nom d’utilisateur, nom ou prénom..." />
                <Table>
                    <TableHead>

                        <TableRow>

                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedAll}
                                    indeterminate={selectedSome}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            onSelectAll?.();
                                        } else {
                                            onDeselectAll?.();
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Username
                            </TableCell>
                            <TableCell>
                                isActive
                            </TableCell>
                            <TableCell>
                                isAdmin
                            </TableCell>
                        </TableRow>
                    </TableHead >
                    <TableBody>
                        {sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer) => {
                            const isSelected = selected.includes(customer.id || customer.Id);
                            return (
                                <TableRow
                                    hover
                                    key={customer.id || customer.Id}
                                    selected={isSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    onSelectOne?.(customer.id || customer.Id);
                                                } else {
                                                    onDeselectOne?.(customer.id || customer.Id);
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={2}
                                        >
                                            <Avatar src={customer.avatar} sx={{ bgcolor: customer.Name ? 'darkgreen' : 'gray' }}>
                                                {customer.Name ? `G${allItems.indexOf(customer) + 1}` : getInitials(customer.firstName + ' ' + customer.lastName)}
                                            </Avatar>
                                            <Typography variant="subtitle2">
                                                {customer.lastName} {' '}
                                                {customer.firstName}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        {customer.userName || customer.Name}
                                    </TableCell>
                                    <TableCell>
                                        {customer.isActive || customer.Active ?
                                            <SvgIcon fontSize="small">
                                                <CheckIcon />
                                            </SvgIcon> : null
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {customer.isAdmin ? <SvgIcon fontSize="small">
                                            <CheckIcon />
                                        </SvgIcon> : null}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table >
                <TablePagination
                    component="div"
                    count={count}
                    labelRowsPerPage="Ligne par page:"
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            </Box >
        </Card >
    );
};

UserPrivilegeTable.propTypes = {
    items: PropTypes.array,
    groups: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    selected: PropTypes.array,
    count: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func
};

export default UserPrivilegeTable;