// TreeNode.js
/* eslint-disable react/jsx-no-leaked-render */
import React, { useState } from 'react';
import { Box, Checkbox, IconButton, SvgIcon, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TreeNode = ({ node, nodeId, checked, indeterminate, onCheck, onAddCriteria, checkedState, indeterminateState, nodeCriteria = [], displayNameMap, onDeleteCriteria }) => {
    const [expanded, setExpanded] = useState(false);
    const hasChildren = node.children && node.children.length > 0;

    const handleCheck = (event) => {
        onCheck(nodeId, event.target.checked);
    };

    if (!node) return null;
    // Determine the text color based on the node level
    const textColor = nodeId.split('-').length === 1 ? '#4E31AA' : nodeId.split('-').length === 2 ? '#A28B55' : 'black';
    const criteria = nodeCriteria.find(obj => obj.nodeId === nodeId)?.criteria || [];

    const isIndeterminate = indeterminate || criteria.length > 0;

    const formatCriteriaLabel = (criteria) => {
        if (criteria.DBName.toLowerCase().includes('date') && criteria.Value.length === 2) {
            return `${displayNameMap[criteria.DBName]}: ${criteria.Value[0]} à ${criteria.Value[1]}`;
        }
        return `${displayNameMap[criteria.DBName]}: ${criteria.Value.join(' OR ')}`;
    };
    return (
        <div style={{ marginLeft: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    checked={checked}
                    indeterminate={isIndeterminate}
                    onChange={handleCheck}
                />
                {hasChildren && (
                    <IconButton onClick={() => setExpanded(!expanded)} style={{ marginLeft: 5 }}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
                <span style={{ color: textColor }}>{node.label}</span>
                <SvgIcon
                    fontSize="small"
                    onClick={() => onAddCriteria(nodeId)}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                >
                    <AddCircleOutlineIcon />
                </SvgIcon>
            </div>
            {criteria.length > 0 && (
                <Box style={{ marginLeft: 40 }}>
                    {criteria.map((criteria, index) => (
                        criteria.Value && (
                            <Chip
                                key={index}
                                label={formatCriteriaLabel(criteria)}
                                style={{ margin: '5px 0' }}
                                onDelete={() => onDeleteCriteria(nodeId, criteria.DBName)}
                            />
                        )
                    ))}
                </Box>
            )}
            {expanded && hasChildren && (
                <div>
                    {node.children.map((child, idx) => (
                        <TreeNode
                            key={idx}
                            node={child}
                            nodeId={`${nodeId}-${idx}`}
                            checked={checkedState[`${nodeId}-${idx}`] || false}
                            indeterminate={indeterminateState[`${nodeId}-${idx}`] || false}
                            onCheck={onCheck}
                            onAddCriteria={onAddCriteria}
                            checkedState={checkedState || false}
                            indeterminateState={indeterminateState || false}
                            nodeCriteria={nodeCriteria}
                            displayNameMap={displayNameMap}
                            onDeleteCriteria={onDeleteCriteria}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeNode;
