import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useAuth } from '../../Hooks/use-auth';
import { useAuthContext } from '../../Contexts/auth-context';

export const AccountPopover = ({ anchorEl, onClose, open }) => {

    const { user } = useAuthContext();
    const navigate = useNavigate();
    const auth = useAuth();

    const handleSignOut = useCallback(
        () => {
            onClose?.();
            auth.signOut();
            navigate('/', { replace: true });
        },
        [onClose, auth, navigate]
    );

    return (
        <Popover
            PaperProps={{ sx: { width: 200 } }}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={onClose}
            open={open}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Typography variant="overline">
                    Account
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {user.userName}
                </Typography>
            </Box>
            <Divider />
            <MenuList
                dense
                disablePadding
                sx={{
                    p: '8px',
                    '& > *': {
                        borderRadius: 1
                    }
                }}
            >
                <MenuItem onClick={handleSignOut}>
                    Sign out
                </MenuItem>
            </MenuList>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired
};
