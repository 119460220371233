/* eslint-disable react/jsx-max-depth */
import React, { useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Layout as DashboardLayout } from '../Components/Dashboard/index';
import SearchComponent from '../Components/Shared/Search';
import Skeleton from '@mui/material/Skeleton';
import { useUsers } from '../Hooks/use-users';
import TablePagination from '@mui/material/TablePagination';
import Cookies from 'js-cookie';

const Page = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const { users, loading, error: usersError } = useUsers(Cookies.get('access_token'));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (loading) {
    return (
      <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Skeleton height={40} variant="text" width="20%" />
              </div>
              <div>
                <Skeleton height={50} variant="rectangular" width="100%" />
              </div>
              <div>
                <Skeleton height={400} variant="rectangular" width="100%" />
              </div>
            </Stack>
          </Container>
        </Box>
      </DashboardLayout>
    );
  }

  if (usersError) {
    console.error('Users query error:', usersError.message);

    return (
      <DashboardLayout>
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth="lg">
            <Typography color="error">Une erreur s'est produite. Veuillez réessayer.</Typography>
          </Container>
        </Box>
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Utilisateurs</Typography>
            </div>
            <div>
              <SearchComponent handleSearchChange={handleSearchChange} title="rechercher des utilisateurs" />
            </div>
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Prénom</TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell>Nom d'utilisateur</TableCell>
                      <TableCell>Activité</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {((users && users.length > 0) ? users : [])
                      .filter(
                        (user) =>
                          user.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.lastName?.toLowerCase().includes(searchTerm.toLowerCase())
                      )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>{user.firstName}</TableCell>
                          <TableCell>{user.lastName}</TableCell>
                          <TableCell>{user.userName}</TableCell>
                          <TableCell>
                            {user.isActive ? 'Actif' : 'Inactif'}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={users ? users.length : 0}
                  labelRowsPerPage="Ligne par page:"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              </TableContainer>
            </div>
          </Stack>
        </Container>
      </Box>
    </DashboardLayout>
  );
};
export default Page;
