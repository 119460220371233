/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable quotes */
/* eslint-disable react/jsx-sort-props */
import { useEffect, useMemo, useState, useRef } from 'react';
import { Alert, Card, Divider, Snackbar, Chip, Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { stepsConfig } from './config';
import PickCabinetStep from './PickCabinetStep';
import StepperComponent from '../Shared/Stepper';
import DefineModelTree from './DefineModelTree';
import { useModelTree, useSelection } from '../../Hooks';
import DefineUserPrivilege from './DefineUserPrivilege';
import useCustomForm from '../../Hooks/useCustomFormik';
import CustomCardAction from '../Shared/CustomCardAction';
import { GET_PERMISSION_MODEL } from '../../Graphql/Query/get-permissionmodel-query';
import { useLazyQuery } from '@apollo/client';
import { useUsers } from '../../Hooks/use-users';
import { useGroups } from '../../Hooks/use-groups';
import Cookies from 'js-cookie';
import Tree from '../Shared/Tree';
import DefineUserCriteria from './DefineUserCriteria.js';
import { useAdminSearchCriteria } from '../../Hooks/use-Docuware.js';

const useGroupIds = (groups) => {
  return useMemo(() => {

    const groupIds = groups ? groups.map((group) => group.Id) : [];
    return [...groupIds];

  }, [groups]);

};
const useUserIds = (users) => {
  return useMemo(() => {
    const userIds = users ? users.map((user) => user.id) : [];
    return [...userIds];
  }, [users]);
};

const CreateModelTree = ({ fileCabinetList, loading, isEditMode = false, model = null }) => {
  const [activeStep, setActiveStep] = useState(isEditMode ? 1 : 0);
  const [condition, setCondition] = useState(
    isEditMode ? model.jsonarrayfilter : ['test']
  );
  const [filecabinetid, setFilecabinetid] = useState(isEditMode ? model.filecabinetId : null);

  const isFirstRender = useRef(true);
  const [treeData, setTreeData] = useState([]);
  const [operator, setOperator] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openUpdateSnackbar, setOpenUpdateSnackbar] = useState(false);
  const [isNextDisabled, setDisabledNext] = useState(false);
  const [isAddLoopDisabled, setDisabledAddLoop] = useState(false);

  const [isReadyToUpdate, setIsReadyToUpdate] = useState(false);

  const [loops, setLoops] = useState(isEditMode ? model.loops : [{ title: 'Filtre 1', condition: [], treeData: [] }]);
  const [activeLoopIndex, setActiveLoopIndex] = useState(0);
  const [loopCount, setLoopCount] = useState(isEditMode ? model.loops.length : 1);
  const loopsLimit = Number(process.env.LOOPS_LIMIT) || 5;

  const { users, error: usersError } = useUsers(Cookies.get('access_token'));
  const { groups } = useGroups(Cookies.get('access_token'));
  const usersIds = useUserIds(users);
  const groupsIds = useGroupIds(groups);
  const userSelection = useSelection(usersIds, groupsIds);
  const modelTree = useModelTree();
  const navigate = useNavigate();

  const [editingChipIndex, setEditingChipIndex] = useState(null);
  const [editedChipText, setEditedChipText] = useState('');
  const [titleMap, setTitleMap] = useState(new Map());

  useEffect(() => {
    const initialTitleMap = new Map();
    if (isEditMode && model && model.loops) {
      model.loops.forEach((loop, index) => {
        const title = loop.title || `Filtre ${index + 1}`;
        initialTitleMap.set(title, true);
      });
    }
    setTitleMap(initialTitleMap);
  }, [isEditMode, model]);

  const generateUniqueTitle = (baseTitle) => {
    let newTitle = baseTitle;
    let counter = 1;
    while (titleMap.has(newTitle)) {
      counter++;
      newTitle = `${baseTitle} ${counter}`;
    }
    return newTitle;
  };

  const handleEditChip = (index) => {
    setEditingChipIndex(index);
    setEditedChipText(loops[index].title);
  };

  const handleSaveChipEdit = (index) => {
    let newTitle = editedChipText.trim() || `Filtre ${index + 1}`;
    if (newTitle !== loops[index].title) {
      newTitle = generateUniqueTitle(newTitle);

      setLoops(prevLoops => prevLoops.map((loop, i) =>
        i === index ? { ...loop, title: newTitle } : loop
      ));
      setTitleMap(prevMap => {
        const newMap = new Map(prevMap);
        newMap.delete(loops[index].title);
        newMap.set(newTitle, true);
        return newMap;
      });
    }
    setEditingChipIndex(null);
  };
  const [searchFields, setSearchFields] = useState([]);
  const { loading: fieldsLoading, error: fieldsError, data: fieldsData } = useAdminSearchCriteria(
    Cookies.get('access_token'),
    filecabinetid,
  );

  const updateLoopTreeData = (newTreeData) => {
    setLoops(prevLoops => prevLoops.map((loop, index) =>
      index === activeLoopIndex ? { ...loop, treeData: newTreeData } : loop
    ));
  };
  useEffect(() => {
    if (!fieldsLoading && fieldsData) {
      setSearchFields(fieldsData.getAdminSearchCriteria);
    }
  }, [fieldsLoading, fieldsData]);


  useEffect(() => {
    if (!isEditMode && activeStep === 0 && isFirstRender.current) {
      resetFields();
      isFirstRender.current = false;
    }
  }, [activeStep, isEditMode]);

  useEffect(() => {
    modelTree.setCookie(Cookies.get('access_token'));
    modelTree.setGroups(groups);
  }, [groups]);

  const resetFields = () => {
    setCondition([]);
    setTreeData([]);
    setOperator('');
    setFilecabinetid(null);
    setLoops([{ title: 'Filtre 1', condition: [], treeData: [] }]);
    setLoopCount(1);
    userSelection.handleDeselectAll();
    if (!isEditMode) {
      setActiveStep(0);
    }
    formik.resetForm();
  };
  useEffect(() => {
    if (isEditMode) {
      setFilecabinetid(model.filecabinetId);
      modelTree.setContextCabinet([{ Id: model.filecabinetId, isSelected: true }]);
      modelTree.setModelTitle(model.title);
    } else {
      setActiveStep(0);
      setCondition([]);
      setTreeData([]);
    }
  }, [model]);

  useEffect(() => {
    if (isEditMode && isReadyToUpdate && activeStep === 4) {
      modelTree.updateModel(model.id_model, loops).then(() => {
        modelTree.updatePermission(model.id_model, userSelection.selected).then(() => {
          // The snackbar closing triggers the navigation to /models and the fields reset
          navigate('/models', { state: { successMessage: "Modèle d'extraction mis à jour avec succès!" } });
          resetFields();
        });
      });
    }
  }, [modelTree.jsonArrayTree, isReadyToUpdate]);
  const [getPermissionModel, { data: permissionData, loading: permissionLoading, error: permissionError }] = useLazyQuery(GET_PERMISSION_MODEL, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (isEditMode && model?.id_model) {
      const fetchPermissionModel = async () => {

        const { data } = await getPermissionModel({ variables: { id_model: model.id_model } });

        if (data) {
          const { users, groups } = data.getOnePermissionModel;
          userSelection.setSelected([...users, ...groups]);
        }
      };

      fetchPermissionModel();
    }
  }, [isEditMode, model]);

  const handleNextStep = async (title) => {
    const actions = {
      0: async () => {
        await modelTree.setContextCabinet([{ Id: filecabinetid, isSelected: true }]);
        await modelTree.setModelTitle(title);
      },
      1: async () => {
      },
      2: async () => {

      },
      3: async () => {
        const nullOperationConditions = condition.filter(item => item.Operation === null);
        await modelTree.setJsonArrayFilter(nullOperationConditions);
      },

      4: async () => {
        if (isEditMode) {
          setIsReadyToUpdate(true);
        } else {
          const { users, groups } = await modelTree.setUsersPrivilege(userSelection.selected);
          await modelTree.setFinalJSON({ users, groups, loops });


          navigate('/models', { state: { successMessage: 'Modèle créée avec succès!' } });

        }
      },
    };
    if (actions[activeStep]) await actions[activeStep]();

    if (activeStep < 5) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const formik = useCustomForm({ fileCabinetList, handleNextStep, isEditMode, model });
  let { cabinetList } = formik.values;
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep - 1;
      if (newActiveStep === 1 && loops.length > 0) {
        setActiveLoopIndex(loops.length - 1);
      }
      return newActiveStep;
    });
  };
  useEffect(() => {
    const currentLoop = loops[activeLoopIndex];
    const isLoopConditionEmpty = !currentLoop || currentLoop.condition.length === 0;
    const isTreeDataEmpty = !currentLoop || currentLoop.treeData.length === 0;
    if (loopCount >= loopsLimit || isLoopConditionEmpty || isTreeDataEmpty) {

      setDisabledAddLoop(true);
    }
    else {
      setDisabledAddLoop(false);
    }

    if (
      (activeStep === 1 && isLoopConditionEmpty) ||
      (activeStep === 2 && (isLoopConditionEmpty || isTreeDataEmpty))
    ) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [activeStep, loops, activeLoopIndex]);

  //this isn't even needed
  const chooseCabinet = (id) => {
    const newCabinetList = cabinetList.map((el) =>
      el.Id === id ? { ...el, isSelected: !el.isSelected } : { ...el, isSelected: false }
    );
    const selected = newCabinetList.find((el) => el.isSelected);
    setFilecabinetid(selected.Id);
    formik.cabinetList = newCabinetList;
  };

  const handleLoopClick = (index) => {
    setActiveLoopIndex(index);
  };


  const handleAddLoop = () => {
    const newTitle = generateUniqueTitle(`Filtre ${loops.length + 1}`);
    setLoops(prevLoops => {
      const newLoops = [...prevLoops, { title: newTitle, condition: [], treeData: [] }];
      return newLoops;
    });
    setTitleMap(prevMap => new Map(prevMap).set(newTitle, true));

    setLoopCount(prevCount => prevCount + 1);
    setActiveLoopIndex(activeLoopIndex + 1);
    setActiveStep(1);
  };
  const handleDeleteLoop = (index) => {

    setLoops(prevLoops => {
      const newLoops = prevLoops.filter((_, i) => i !== index);
      const newActiveIndex = index > 0 ? index - 1 : (newLoops.length > 0 ? newLoops.length - 1 : null);

      setActiveLoopIndex(newActiveIndex);
      setTitleMap(prevMap => {
        const newMap = new Map(prevMap);
        newMap.delete(prevLoops[index].title);
        return newMap;
      });
      return newLoops;
    });
    setLoopCount(prevCount => prevCount - 1);

  };

  const StepsComponents = {
    0: <PickCabinetStep {...{ activeStep, cabinetList, formik, loading, setFilecabinetid }} />,
    1: (

      <Tree
        activeStep={1}
        fileCabinet={filecabinetid}
        setCondition={(newCondition) => {
          setLoops(prevLoops => prevLoops.map((loop, index) =>
            index === activeLoopIndex ? { ...loop, condition: newCondition } : loop
          ));
        }}
        isEditMode={isEditMode}
        initialCondition={loops[activeLoopIndex].condition}
        loopIndex={activeLoopIndex}

      />
    ),

    2: (
      <DefineModelTree
        {...{
          selectedCabinet: filecabinetid,
          setTreeData: updateLoopTreeData,
          treeData: loops[activeLoopIndex].treeData,
        }}
      />
    ),
    3: (
      <DefineUserCriteria
        activeStep={2}
        searchFields={searchFields}
        setCondition={setCondition}
        condition={condition}
      />

    ),

    4: <DefineUserPrivilege {...{ activeStep, userSelection, users, groups }} />
  };
  const stepsObject = stepsConfig(cabinetList, chooseCabinet, condition, operator, setCondition, setOperator, formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <StepperComponent activeStep={activeStep} steps={stepsObject} />
        {(activeStep === 1 || activeStep === 2) ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2, mt: 2 }}>
            {loops.map((loop, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                {editingChipIndex === index ? (
                  <>
                    <TextField
                      value={editedChipText}
                      onChange={(e) => setEditedChipText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSaveChipEdit(index);
                        }
                      }}
                      onBlur={() => handleSaveChipEdit(index)}
                      size="small"
                      autoFocus
                      sx={{
                        width: '100px',
                        marginLeft: '8px',
                        '& .MuiInputBase-root': {
                          height: '32px',
                        },
                        '& input': {
                          textAlign: 'center',
                          padding: '0 8px',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        },
                      }}
                    />
                    <IconButton onClick={() => handleSaveChipEdit(index)} size="small">
                      <CheckIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Chip
                      label={loop.title || `Filtre ${index + 1}`}
                      onClick={() => handleLoopClick(index)}
                      onDelete={index !== 0 ? () => handleDeleteLoop(index) : undefined}
                      color={activeLoopIndex === index ? 'primary' : 'default'}
                    />
                    <IconButton onClick={() => handleEditChip(index)} size="small">
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            ))}
          </Box>
        ) : null}
        {StepsComponents[activeStep]}
        <Divider />
        <CustomCardAction
          activeStep={activeStep}
          formik={formik}
          handleAddLoop={handleAddLoop}
          handleBack={handleBack}
          isEditMode={isEditMode}
          isNextDisabled={isNextDisabled}
          stepsObject={stepsObject}
          isAddLoopDisabled={isAddLoopDisabled}
        />
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%', fontSize: '1.5em' }}>
          Modèle d'extraction créé!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={1000}
        onClose={() => {
          setOpenUpdateSnackbar(false);
          navigate('/models'); // navigate after Snackbar is closed (either manually or after 2 seconds)
          resetFields();
        }}
        open={openUpdateSnackbar}
      >
        <Alert
          onClose={() => setOpenUpdateSnackbar(false)}
          severity="success"
          sx={{ width: '100%', fontSize: '1.5em' }}
        >
          Modèle d'extraction mis à jour!
        </Alert>
      </Snackbar>
    </form>
  );
};
export default CreateModelTree;
