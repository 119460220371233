import { useContext } from 'react';
import { DocuWareContext } from '../Contexts/docu-ware-context';
import { GET_ADMIN_SEARCH_CRITERIA, GET_AUTO_COMPLETE_SEARCH, GET_CABINETS, GET_FULL_TREE_VALUES } from '../Graphql/Query/get-searchcriteria-query';
import { useQuery } from '@apollo/client';

export const useDocuWare = () => useContext(DocuWareContext);

export const useCabQuery = (cookie) => {
    const { loading, error, data } = useQuery(GET_CABINETS, {
        variables: { cookie: cookie },
        fetchPolicy: 'network-only',
    });
    return { loading, error, data };
};


export const useAdminSearchCriteria = (cookie, cabinetId) => {
    const { loading, error, data } = useQuery(GET_ADMIN_SEARCH_CRITERIA, {
        variables: {
            cookie: cookie,
            cabinetId
        },
        fetchPolicy: 'cache-and-network',
        skip: !cabinetId,
    });
    return { loading, error, data };
};
export const useGetAutocompleteSearch = (cookie, cabinetId, nameField) => {
    const { loading, error, data } = useQuery(GET_AUTO_COMPLETE_SEARCH, {
        skip: !nameField,
        variables: {
            cookie: cookie,
            cabinetId,
            nameField
        },
        fetchPolicy: 'no-cache',
    });
    return { loading, error, data };
};
export const useGetFullTreeValues = (cookie, cabinetId) => {
    const { loading, error, data } = useQuery(GET_FULL_TREE_VALUES, {
        variables: {
            cookie: cookie,
            cabinetId
        },
        fetchPolicy: 'cache-and-network',

    });
    return { loading, error, data };
};