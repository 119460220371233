import { gql } from '@apollo/client';

export const GET_PERMISSION_MODEL = gql`
  query GetPermissionModel($id_model: Int!) {
    getOnePermissionModel(id_model: $id_model) {
      id
      id_model
      users
      groups
    }
  }
`;
