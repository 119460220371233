/* eslint-disable react/jsx-max-depth */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import client from './Graphql';
import { AuthProvider } from './Contexts/auth-context';
import { ModelTreeProvider } from './Contexts/model-tree-context';
import { DocuWareProvider } from './Contexts/docu-ware-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ModelTreeProvider>
            <DocuWareProvider>
              <App />
            </DocuWareProvider>
          </ModelTreeProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
