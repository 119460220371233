import { gql } from '@apollo/client';

const UPDATE_EXTRACTION_STATUS = gql`
  mutation UpdateExtractionStatus($id: Int!, $newStatus: String!) {
    updateExtractionStatus(id: $id, newStatus: $newStatus) {
      id
      title
      status
    }
  }
`;

export default UPDATE_EXTRACTION_STATUS;