import { useQuery } from '@apollo/client';
import GET_USERS_QUERY from '../Graphql/Query/get-users-query';

export const useUsers = (cookie) => {
  const { loading, error, data } = useQuery(GET_USERS_QUERY, {
    variables: { cookie },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return { loading: true };
  }

  if (error) {
    return { error: true };
  }

  return { users: data.getUsers };
};

