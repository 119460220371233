import DefineUserCriteria from './DefineUserCriteria.js';
import PickCabinetStep from './PickCabinetStep';
import DefineModelTree from './DefineModelTree';
import DefineUserPrivilege from './DefineUserPrivilege';
import Tree from '../Shared/Tree';
import { treeMockData } from '../../utils/mock-data';

export const stepsConfig = (
    cabinetList,
    onClickHandler,
    condition,
    operator,
    setCondition,
    setOperator,
    formik
) => ([
    {
        id: 'Sélectionner l’armoire de fichiers',
        component: <PickCabinetStep
            activeStep={0}
            cabinetList={cabinetList}
            formik={formik}
            onClickHandler={onClickHandler}
        />
    },
    {
        id: 'Définir les filtres obligatoires',
        // component: <DefineSearchCriteria
        //     activeStep={1}
        //     condition={condition}
        //     operator={operator}
        //     setCondition={setCondition}
        //     setOperator={setOperator}
        // />
        component: <Tree
            activeStep={1}
            data={treeMockData}
            setData={() => { }}

        />
    },
    {
        id: 'Définir l’arborescence',
        component: <DefineModelTree activeStep={3} />
    },
    {
        id: 'Définir l’écran de recherche utilisateur',
        component: <DefineUserCriteria activeStep={2} />
    },

    {
        id: 'Définir l’accès utilisateur/groupe',
        component: <DefineUserPrivilege activeStep={4} />
    }
]);

export const usersData = [{
    id: '5e887ac47eed253091be10cb',
    avatar: '/assets/avatars/avatar-carson-darrin.png',
    Username: 'carson.darrin@devias.io',
    lastName: 'Carson',
    firstName: 'Darrin',
    isAdmin: false,
    isActive: true
},
{
    id: '5e887b209c28ac3dd97f6db5',
    avatar: '/assets/avatars/avatar-fran-perez.png',
    createdAt: '12/12/2024',
    Username: 'fran.perez@devias.io',
    lastName: 'Fran',
    firstName: 'Perez',
    isAdmin: false,
    isActive: true
},
{
    id: '5e887b7602bdbc4dbb234b27',
    avatar: '/assets/avatars/avatar-jie-yan-song.png',
    createdAt: '12/12/2024',
    Username: 'jie.yan.song@devias.io',
    firstName: 'Jie Yan',
    lastName: 'Song',
    isAdmin: false,
    isActive: true
},
{
    id: '5e86809283e28b96d2d38537',
    avatar: '/assets/avatars/avatar-anika-visser.png',
    createdAt: '12/12/2024',
    Username: 'anika.visser@devias.io',
    firstName: 'Anika',
    lastName: 'Visser',
    isAdmin: true,
    isActive: true
},
{
    id: '5e86805e2bafd54f66cc95c3',
    avatar: '/assets/avatars/avatar-miron-vitold.png',
    createdAt: '12/12/2024',
    Username: 'miron.vitold@devias.io',
    firstName: 'Miron',
    lastName: 'Vitold',
    isAdmin: false,
    isActive: true
},
{
    id: '5e887a1fbefd7938eea9c981',
    avatar: '/assets/avatars/avatar-penjani-inyene.png',
    createdAt: '12/12/2024',
    Username: 'penjani.inyene@devias.io',
    firstName: 'Penjani',
    lastName: 'Inyene',
    isAdmin: false,
    isActive: true
},];