import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import { Card, InputAdornment, OutlinedInput, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const SearchComponent = ({title, defaultValue, handleSearchChange}) => (
    <Card sx={{ p: 2 }}>
        <OutlinedInput
            defaultValue={defaultValue}
            fullWidth
            onChange={handleSearchChange}
            placeholder={title}
            startAdornment={(
                <InputAdornment position="start">
                    <SvgIcon
                        color="action"
                        fontSize="small"
                    >
                        <MagnifyingGlassIcon />
                    </SvgIcon>
                </InputAdornment>
            )}
            sx={{ maxWidth: 500 }}
        />
    </Card>
);

SearchComponent.propTypes = {
    title: PropTypes.string,
    defaultValue: PropTypes.string,
    handleSearchChange: PropTypes.func,
};
export default SearchComponent;