import React, { useState, useEffect } from 'react';
import {
    CardContent,
    Typography,
    Grid,
    Chip,
    Box,
    FormGroup,
    InputLabel,
    Checkbox,
    MenuItem,
    Select,
    FormControl,
    Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
const DefineUserCriteria = ({

    activeStep,
    condition,
    setCondition,
    searchFields,
}
) => {
    const [userDefinedFields, setUserDefinedFields] = useState([]);
    const [selectedField, setSelectedField] = useState('');
    const [isMandatory, setIsMandatory] = useState(false);
    useEffect(() => {
        const nullOperationObject = condition[0];
        if (nullOperationObject) {
            setUserDefinedFields(nullOperationObject.Condition);
        } else {
            setUserDefinedFields([]);
        }

    }, [condition]);

    const handleFieldChange = (event) => {
        setSelectedField(event.target.value);
    };

    const handleMandatoryChange = (event) => {
        setIsMandatory(event.target.checked);
    };

    const handleAddField = () => {
        if (selectedField) {
            let newCondition = [...condition];
            let nullOperationObject = newCondition.find(obj => obj.Operation === null);
            if (nullOperationObject) {
                const existingFieldIndex = nullOperationObject.Condition.findIndex(f => f.DBName === selectedField);
                if (existingFieldIndex !== -1) {
                    nullOperationObject.Condition[existingFieldIndex].mandatory = isMandatory;
                } else {
                    nullOperationObject.Condition.push({ DBName: selectedField, Value: [], mandatory: isMandatory });
                }
            } else {
                newCondition.push({
                    id: uuidv4(),
                    Condition: [{ DBName: selectedField, Value: [], mandatory: isMandatory }],
                    Operation: null,
                });
            }
            setCondition(newCondition);
            setSelectedField('');
            setIsMandatory(false);
        }

    };
    const getDisplayName = (dbName) => {
        const field = searchFields.find(field => field.DBFieldName === dbName);

        return field ? field.DisplayName : dbName;
    };

    const handleDeleteField = (dbName) => {
        const newCondition = [...condition];
        const nullOperationObject = newCondition.find(obj => obj.Operation === null);
        if (nullOperationObject) {
            nullOperationObject.Condition = nullOperationObject.Condition.filter(f => f.DBName !== dbName);
            setUserDefinedFields(nullOperationObject.Condition);
            // This is just for clarity, the above code actually does this because how JS compares copies etc, 
            //weird but it works, this below is just so i don't lose my mind when coming back to this
            setCondition(newCondition);
        }
    };
    const filteredSearchFields = searchFields.filter(field => !['CHAPITRE', 'SOUS_CHAPITRE', 'TYPE_DOCUMENT'].includes(field.DBFieldName));

    return (
        <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Définir les critères utilisateur
            </Typography>
            <Grid container spacing={5} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="field-select-label">Sélectionner un champ</InputLabel>
                            <Select
                                labelId="field-select-label"
                                value={selectedField}
                                label="Sélectionner un champ"
                                onChange={handleFieldChange}
                            >
                                {filteredSearchFields.map((field) => (
                                    <MenuItem key={field.DBFieldName} value={field.DBFieldName}>
                                        {field.DisplayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormGroup>
                            <InputLabel>Obligatoire</InputLabel>
                            <Checkbox
                                checked={isMandatory}
                                onChange={handleMandatoryChange}
                            />
                        </FormGroup>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddField}
                            sx={{ ml: 2 }}
                        >
                            Ajouter
                        </Button>
                    </Box>
                </Grid>
                {userDefinedFields.map((field) => (
                    <Grid item xs={12} key={field.DBName}>
                        <Chip
                            label={`${getDisplayName(field.DBName)}: ${field.mandatory ? 'Obligatoire' : 'Optionnel'}`}
                            color={field.mandatory ? 'primary' : 'default'}
                            sx={{ marginLeft: 10 }}
                            onDelete={() => handleDeleteField(field.DBName)}
                        />
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    );
};

DefineUserCriteria.propTypes = {
    condition: PropTypes.array,
    setCondition: PropTypes.func,
    searchFields: PropTypes.array,
};

export default DefineUserCriteria;