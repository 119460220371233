export const treeMockData = [
    {
        'label': '1 - Droit de propriété',
        'children': [
            {
                'label': '1.1 - Promesse',
                'children': [
                    {
                        'label': '1.1.01 - Promesse de vente'
                    },
                    {
                        'label': '1.1.02 - Pièce d\'urbanisme'
                    }
                ]
            },
            {
                'label': '1.2 - Actes',
                'children': [
                    {
                        'label': '1.2.01 - Acte de vente'
                    },
                    {
                        'label': '1.2.03 - Situation hypothécaire'
                    },
                    {
                        'label': '1.2.04 - Attestation de propriété'
                    },
                    {
                        'label': '1.2.05 - Relevé de notaire'
                    }
                ]
            }
        ]
    },
    {
        'label': '2 - Developpement / Montage opérationnel',
        'children': [
            {
                'label': '2.1 - Concours / Protocoles',
                'children': [
                    {
                        'label': '2.1.01 - Concours'
                    },
                    {
                        'label': '2.1.02 - Convention collectivités locales et protocoles'
                    },
                    {
                        'label': '2.1.03 - Appel d\'offre'
                    }
                ]
            },
            {
                'label': '2.2 - VEFA / Cession',
                'children': [
                    {
                        'label': '2.2.01 - Promesse de VEFA'
                    },
                    {
                        'label': '2.2.02 - Vente de VEFA'
                    }
                ]
            }
        ]
    }
];