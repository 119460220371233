import { gql } from '@apollo/client';

const GET_USERS_QUERY = gql`
  query GetUsers($cookie: String!) {
    getUsers(cookie: $cookie) {
      id
      firstName
      lastName
      userName
      isActive
      isAdmin
    }
  }
`;

export default GET_USERS_QUERY;
