import { gql } from '@apollo/client';

const DELETE_EXTRACTION = gql`
  mutation DeleteExtraction($id: Int!) {
    deleteExtraction(id: $id) {
      id
      user_id
      create_at
      status
      
    }
  }
`;

export default DELETE_EXTRACTION;
