import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ArrowRight, Delete, FileCopy } from '@mui/icons-material';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { TypeIcon } from './TypeIcon';
import styles from './styles/CustomNode.module.css';


export const CustomNode = ({node, depth, isOpen, onToggle, onDelete, onCopy}) => {
    const [hover, setHover] = useState(false);
    const { id, droppable, data } = node;
    const indent = depth * 24;

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle(node.id);
    };

    const dragOverProps = useDragOver(id, isOpen, onToggle);

    return (
        <div
            className={`tree-node ${styles.root}`}
            style={{ paddingInlineStart: indent }}
            {...dragOverProps}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div
                className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ''
                    }`}
            >
                {node.droppable ? <div onClick={handleToggle}>
                        <ArrowRight />
                    </div> : null}
            </div>
            <div>
                <TypeIcon droppable={droppable} fileType={data?.fileType} />
            </div>
            <div className={styles.labelGridItem}>
                <Typography variant="body2">{node.text}</Typography>
            </div>
            {hover ? <>
                    <div className={styles.actionButton}>
                        <IconButton onClick={() => onDelete(id)} size="small">
                            <Delete fontSize="small" />
                        </IconButton>
                    </div>
                    <div className={styles.actionButton}>
                        <IconButton onClick={() => onCopy(id)} size="small">
                            <FileCopy fontSize="small" />
                        </IconButton>
                    </div>
                </> : null}
        </div>
    );
};
