import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import backgroundImage from '../../assets/Background-Docuroom.png';


export const Layout = ({ children }) => {


    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flex: '1 1 auto'
            }}
        >
            <Grid
                container
                sx={{ flex: '1 1 auto' }}
            >
                <Grid
                    lg={6}
                    sx={{
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                    xs={12}
                >
                    <Box
                        component="header"
                        sx={{
                            left: 0,
                            p: 3,
                            position: 'fixed',
                            top: 0,
                            width: '100%'
                        }}
                    >
                        <Box
                            component={Link}
                            href="/"
                            sx={{
                                display: 'inline-flex',
                                height: 32,
                                width: 32
                            }}
                        >
                        </Box>
                    </Box>
                    {children}
                </Grid>
                <Grid
                    lg={6}
                    sx={{
                        alignItems: 'center',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                    xs={12}
                >
                    <img
                        src={backgroundImage}
                        alt="Docuroom Background"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            zIndex: -1
                        }}
                    />

                </Grid>
            </Grid>
        </Box>
    );
};

Layout.prototypes = {
    children: PropTypes.node
};