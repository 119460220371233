import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
export const DateInputs = ({ startDate, setStartDate, endDate, setEndDate }) => {
    const [startDateInput, setStartDateInput] = useState(startDate);
    const [endDateInput, setEndDateInput] = useState(endDate);
    const handleStartDateChange = (date) => {

        setStartDateInput(date);

        if (dayjs(date, 'MM-DD-YYYY').isValid()) {
            setStartDate(dayjs(date, 'MM-DD-YYYY').format('MM-DD-YYYY'));
        } else {
            setStartDateInput(null);
            setStartDate(null);
        }
    };

    const handleEndDateChange = (date) => {

        setEndDateInput(date);

        if (dayjs(date, 'MM-DD-YYYY').isValid()) {
            setEndDate(dayjs(date, 'MM-DD-YYYY').format('MM-DD-YYYY'));
        } else {
            setEndDateInput(null);
            setEndDate(null);
        }

    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                format="DD-MM-YYYY"
                label="Date début"
                value={startDateInput}
                onChange={handleStartDateChange} />
            <DatePicker
                format="DD-MM-YYYY"
                label="Date fin"
                value={endDateInput}
                onChange={handleEndDateChange} />
        </LocalizationProvider>
    );
};
