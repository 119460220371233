import { gql } from '@apollo/client';

export const GET_ACCESSIBLE_MODELS = gql`
  query GetAccessibleModels($cookie: String!, $userId: String!) {
    getAccessibleModels(cookie: $cookie, user_id: $userId) {
      id_model
      title
      filecabinetId
      jsonarraytree {
        id
        path {
          id
          parent
          droppable
          DBFieldName
          text
        }
      }
      jsonarrayfilter {
        id
        Condition {
          DBName
          Value
          mandatory
        }
        Operation
      }
      loops {
        title
        condition {
          id
          Condition {
            DBName
            Value
            mandatory
          }
          Operation
        }
        treeData {
          id
          parent
          droppable
          DBFieldName
          text
        }
      }
    }
  }
`;
