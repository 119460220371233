/* eslint-disable react/jsx-max-depth */
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Typography, SvgIcon, CardActionArea } from '@mui/material';

import InboxStack from '@heroicons/react/24/solid/InboxStackIcon';


const CardComponent = ({ id, title, description, isSelected, formik }) => {


    return (
        <Card
            onClick={() => formik.setFieldValue('cabinetList', formik.values.cabinetList.map(el => el.Id === id ? { ...el, isSelected: !el.isSelected } : { ...el, isSelected: false }))}
            raised
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                bgcolor: isSelected ? '#E5E7EB' : ''
            }}
        >
            <CardActionArea ac="true">
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            pb: 3
                        }}
                    >
                        <Avatar
                            variant="circle"
                        >
                            <SvgIcon fontSize="small">
                                <InboxStack />
                            </SvgIcon>
                        </Avatar>
                    </Box>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h5"
                    >
                        {title}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                    >
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

CardComponent.propTypes = {
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    formik: PropTypes.object
};
export default CardComponent;