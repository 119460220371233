import XCircleIcon from '@heroicons/react/24/solid/XCircleIcon';
import { Box, Chip, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';


const CriteriaChip = ({
    valueToDisplay,
    removeCriteria,
    isOptional = false,
    isMandatory = false,
    hideFilledMandatory = false,
}) => {
    if (hideFilledMandatory) {
        return null;
    }
    return (
        <Box>
            <Chip label={valueToDisplay} style={{ backgroundColor: (isOptional || !isMandatory) ? 'lightblue' : 'default' }} />
            <SvgIcon fontSize="small" onClick={removeCriteria}>
                <XCircleIcon />
            </SvgIcon>
        </Box>
    );
};

CriteriaChip.propTypes = {
    valueToDisplay: PropTypes.string,
};

export default CriteriaChip;
