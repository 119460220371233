import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import GET_USERS_QUERY from '../Graphql/Query/get-users-query';

const HANDLERS = {
    INITIALIZE: 'INITIALIZE',
    SET_CABINETS: 'SET_CABINETS',
    SET_USERS: 'SET_USERS',
    SET_SEARCH_CRITERIA: 'SET_SEARCH_CRITERIA',
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
};

const initialState = {
    cabinets: [],
    usersList: [],
    searchCriteria: [],
    isLoading: false,
    error: null
};

const handlers = {
    [HANDLERS.INITIALIZE]: (state, { payload }) => {
        state = payload;
    },
    [HANDLERS.SET_CABINETS]: (state, { payload }) => {
        return ({
            ...state,
            cabinets: payload,
            isLoading: false,
        });
    },
    [HANDLERS.SET_USERS]: (state, action) => {
        return ({
            ...state,
            usersList: action.payload,
            isLoading: false
        });
    },
    [HANDLERS.SET_SEARCH_CRITERIA]: (state, { payload }) => {
        state.searchCriteria = payload;
    },
    [HANDLERS.SET_LOADING]: (state, { payload }) => {
        return ({
            ...state,
            isLoading: payload,
        });
    },
    [HANDLERS.SET_ERROR]: (state, { payload }) => {
        return ({
            ...state,
            error: payload,
            isLoading: false
        });
    }
};

const reducer = (state, action) => (
    handlers[action.type]
        ? handlers[action.type](state, action)
        : state
);
export const DocuWareContext = createContext({ undefined });

export const DocuWareProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const initialized = useRef(false);
    const initialize = async () => {
        // Prevent from calling twice in development mode with React.StrictMode enabled
        if (initialized.current) {
            return;
        }
        initialized.current = true;
    };
    useEffect(
        () => {
            initialize();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const users = useQuery(GET_USERS_QUERY, {
        errorPolicy: 'all',
    });
    const getUsersList = async () => {
        const { loading, error, data } = users;

        if (loading) {
            dispatch({
                type: HANDLERS.SET_LOADING,
                payload: true,
            });
        }
        if (error) {
            dispatch({
                type: HANDLERS.SET_ERROR,
                payload: error
            });
        }
        if (data?.getUsers) {
            dispatch({
                type: HANDLERS.SET_USERS,
                payload: data.getUsers
            });
        }
    };

    const setCabinets = ({ loading, error, data }) => {
        dispatch({
            type: HANDLERS.SET_LOADING,
            payload: loading
        });

        dispatch({
            type: HANDLERS.SET_LOADING,
            payload: true
        });

        if (data && data.getCabinets) {
            dispatch({
                type: HANDLERS.SET_CABINETS,
                payload: data.getCabinets
            });
        };

        if (error) {
            dispatch({
                type: HANDLERS.SET_ERROR,
                payload: error
            });
        }

        dispatch({
            type: HANDLERS.SET_LOADING,
            payload: false
        });
    };
    return (
        <DocuWareContext.Provider
            value={{
                ...state,
                getUsersList,
                setCabinets

            }}
        >
            {children}
        </DocuWareContext.Provider>
    );
};

DocuWareProvider.propTypes = {
    children: PropTypes.node,
};

export const DocuWareConsumer = DocuWareContext.Consumer;

export const useDocuWareContext = () => useContext(DocuWareContext);
