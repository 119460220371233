// getMyExtractions.js
import { gql } from '@apollo/client';

export const GET_MY_EXTRACTIONS = gql`
  query GetMyExtractions($userId: String!) {
    getMyExtractions(user_id: $userId) {
      id
      title
      user_id
      create_at
      zip_link
      status
      log_link
    }
  }
`;
