import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../Hooks/index';
export const AuthGuard = ({ children }) => {
    const { checkisSessionExpired } = useAuth();
    const navigate = useNavigate();
    const ignore = useRef(false);
    const [checked, setChecked] = useState(false);

    // Only do authentication check on component mount.
    // This flow allows you to manually redirect the user after sign-out, otherwise this will be
    // triggered and will automatically redirect to sign-in page.
    const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));

    // Call checkSessionExpiration to check if the session has expired
    useEffect(() => {
        const checkAuth = async () => {
            // Prevent from calling twice in development mode with React.StrictMode enabled
            if (ignore.current) {
                return;
            }

            ignore.current = true;
            const isExpired = await checkisSessionExpired();
            if (!isAuthenticated || isExpired) {
                console.log('Not authenticated, redirecting');
                navigate('/', {
                    replace: true,
                });
            } else {
                setChecked(true);
            }

        };
        checkAuth();
    },
        [isAuthenticated, navigate, checkisSessionExpired]
    );

    if (!checked) {
        return null;
    }

    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // authenticated / authorized.

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};
