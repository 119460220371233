// updateModelMutation.js
import { gql } from '@apollo/client';

const UPDATE_MODEL_MUTATION = gql`
  mutation UpdateModel($model_id: Int!, $newFilterArray: JSON!, $loops: JSON!) {
    updateModel(model_id: $model_id, newFilterArray: $newFilterArray, loops: $loops)
  }
`;

export default UPDATE_MODEL_MUTATION;
